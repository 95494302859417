<template>
  <Modal :open="open" :keep-open="true" width="w-1/3" @update:open="onClose">
    <template #body>
      <span class="font-medium"> Columna: {{ weekDeparture.name }} </span>
      <br />
      <div class="pt-5">
        <Form
          @submit="onSave"
          :validation-schema="{
            userKey: 'required',
          }"
        >
          <div class="mb-3">
            <Field v-model="userKey" name="userKey" v-slot="{ field }">
              <select
                v-bind="field"
                class="
                  relative
                  py-2
                  px-2
                  w-full
                  bg-white
                  rounded-sm
                  border
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                "
                placeholder="Seleccione"
              >
                <option value="">Seleccione responsable</option>
                <option
                  v-for="member in getMembers"
                  :key="member.key"
                  :value="member.key"
                >
                  {{ member.lastName }} {{ member.lastName2 }} {{ member.name }}
                </option>
              </select>
            </Field>
            <div>
              <ErrorMessage name="userKey" v-slot="{ message }">
                <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
              </ErrorMessage>
            </div>
          </div>

          <div class="pt-3 pb-5 sm:flex sm:flex-row-reverse">
            <button
              class="
                rounded
                bg-primary
                py-2
                px-8
                text-white
                font-medium
                flex
                ml-2
              "
              type="submit"
              :disabled="loading"
            >
              <LoadingButtonIcon v-if="loading" />
              Actualizar
            </button>
            <button
              type="text"
              class="
                mt-3
                w-full
                inline-flex
                justify-center
                rounded-md
                border border-gray-300
                shadow-sm
                px-4
                py-2
                bg-white
                text-base
                font-medium
                text-gray-700
                hover:bg-gray-50
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
                sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
              "
              @click="onClose()"
              ref="cancelButtonRef"
            >
              Cancelar
            </button>
          </div>
        </Form>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";

const emit = defineEmits(["update:open"]);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  weekDeparture: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const { value: userKey } = useField("userKey");

const loading = ref(false);

const getMembers = computed(() => store.getters.getMembers);

const init = () => {
  userKey.value = props.weekDeparture.userKey;
};

init();

const onClose = () => {
  emit("update:open", false);
};

const onSave = async () => {
  try {
    loading.value = true;

    await store.dispatch("updateWeekDeparture", {
      weekDeparture: {
        key: props.weekDeparture.key,
        userKey: userKey.value,
      },
    });

    onClose();
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};
</script>
