<template>
  <Modal :open="open" width="w-1/3" @update:open="onClose">
    <template #body>
      <span class="font-medium">
        ¿Desea eliminar la columna "{{ weekDeparture.name }}"?
      </span>
      <br />
    </template>
    <template #buttonDelete>
      <button
        type="button"
        class="
          w-full
          inline-flex
          justify-center
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          bg-red-600
          text-base
          font-medium
          text-white
          hover:bg-red-700
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
          sm:ml-3 sm:w-auto sm:text-sm
        "
        :disabled="loading"
        @click="onDelete"
      >
        <LoadingButtonIcon v-if="loading" />
        Eliminar
      </button>
    </template>
  </Modal>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["update:open"]);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  weekDeparture: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const loading = ref(false);

const onClose = () => {
  emit("update:open", false);
};

const onDelete = async () => {
  try {
    loading.value = true;

    await store.dispatch("deleteWeekDeparture", {
      key: props.weekDeparture.key,
    });

    onClose();
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};
</script>
