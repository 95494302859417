<script setup>
import { defineProps, defineEmits, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";
import dayjs from "dayjs";

const props = defineProps({
  weekDepartureKey: {
    type: String,
    required: true,
  },
  departureKey: {
    type: String,
    required: true,
  },
  initialDate: {
    type: String,
    required: true,
  },
  weekKey: {
    type: String,
    required: true,
  },
  isCurrentWeek: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["onComplete"]);

const store = useStore();

const { value: name } = useField("name");
// const { value: date } = useField("date");
const { value: metered } = useField("metered");
const { value: unit } = useField("unit");
const { value: sectorKey } = useField("sectorKey");

// const availableSectors = ref([]);
const searchTerm = ref(null);
const showOptions = ref("");
const filteredSectors = ref([]);
// const tooltipVisible = ref(false);
// const tooltipMember = ref(null);
const color = ref("");
const colors = ref([
  "#B6D7A8",
  "#A2C4C9",
  "#D5A6BD",
  "#F6B26A",
  "#BDBDBD",
  "#0AFF00",
  "#00FFFF",
  "#4A86E8",
  "#FF01FF",
  "#9900FF",
]);
const isToDispatch = ref(false);
const dates = ref([]);
const loadingSave = ref(false);

const getSchedule = computed(() => store.getters.getSchedule);
const getSectors = computed(() => store.getters.getSectors);
const getWeekDepartures = computed(() => store.getters.getWeekDepartures);

watch(
  () => searchTerm.value,
  (value) => {
    const sector = getSectors.value.find((s) => s.name === value);
    if (!sector) sectorKey.value = null;
  }
);

// const updateAvailableSectors = () => {
//   availableSectors.value = getSectors.value.filter(
//     (s) => !getSchedule.value.schedule.find((sch) => sch.sector.key === s.key)
//   );
// };

const init = () => {
  for (let i = 0; i < 6; i++) {
    dates.value.push(
      dayjs(props.initialDate).add(i, "day").format("YYYY-MM-DD")
    );
  }
};

init();

// const showTooltip = (member) => {
//   tooltipMember.value = member;
//   tooltipVisible.value = true;
// };

// const hideTooltip = () => {
//   tooltipVisible.value = false;
//   tooltipMember.value = null;
// };

const filterOptions = () => {
  filteredSectors.value = getSectors.value.filter((sector) =>
    sector.name.toLowerCase().includes(searchTerm.value.toLowerCase())
  );
};

const selectSector = (sector) => {
  sectorKey.value = sector.key;
  searchTerm.value = sector.name;
  showOptions.value = false;
};

const handleBlur = () => {
  // Delay to allow selection before hiding options
  setTimeout(() => {
    showOptions.value = false;
  }, 100);
};

const onSelectColor = (colorSelected) => {
  color.value = color.value !== colorSelected ? colorSelected : "";
};

const onSaveSchedule = async (values, { resetForm }) => {
  try {
    loadingSave.value = true;

    const departureSchedules = getSchedule.value.schedule.filter(
      (schedule) => schedule.departure.key === props.departureKey
    );

    const weekDeparture = getWeekDepartures.value.find(
      (weekDeparture) => weekDeparture.key === props.weekDepartureKey
    );

    const schedule = await store.dispatch("createSchedule", {
      schedule: {
        type: "Weekly",
        name: values.name || "",
        weekKey: props.weekKey,
        date: props.initialDate,
        color: color.value,
        status: props.isCurrentWeek ? "RegisteredAfterDate" : "Registered",
        timeout: props.isCurrentWeek,
        isToDispatch: isToDispatch.value,
        sort: isToDispatch.value ? 1 : departureSchedules.length + 1,
        userKey:
          weekDeparture?.userKey !== "00000000-0000-0000-0000-000000000000"
            ? weekDeparture.userKey
            : null,
        departureKey: props.departureKey,
        sectorKey: sectorKey.value,
        unit: unit.value,
        metered: metered.value,
      },
    });

    onCloseForm(schedule);

    resetForm();
  } catch (error) {
    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};

const onCloseForm = (schedule) => {
  name.value = "";
  emit("onComplete", schedule);
};
</script>

<template>
  <Form
    @submit="onSaveSchedule"
    :validation-schema="{
      sectorKey: 'required',
      metered: 'decimal',
    }"
  >
    <div class="mb-3 relative">
      <Field v-model="sectorKey" name="sectorKey" v-slot="{ field }">
        <input type="hidden" v-bind="field" />
      </Field>
      <input
        type="text"
        v-model="searchTerm"
        @input="filterOptions"
        @focus="showOptions = true"
        @blur="handleBlur"
        placeholder="Seleccione sector"
        class="
          relative
          py-3
          px-2
          w-full
          bg-white
          rounded-sm
          border
          outline-none
          text-gray-700
          placeholder-gray-400
        "
      />
      <ul
        v-if="showOptions && filteredSectors.length"
        class="
          absolute
          z-10
          bg-white
          border
          rounded-sm
          w-full
          mt-1
          max-h-60
          overflow-auto
        "
      >
        <li
          v-for="(sector, i) in filteredSectors"
          :key="i"
          @mousedown="selectSector(sector)"
          class="px-2 py-1 cursor-pointer hover:bg-gray-200"
        >
          {{ sector.name }}
        </li>
      </ul>
      <div>
        <ErrorMessage name="sectorKey" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>

    <div class="mb-2">
      <Field v-model="name" name="name" v-slot="{ field }">
        <textarea
          v-bind="field"
          class="
            relative
            py-2
            px-2
            w-full
            bg-white
            rounded-sm
            border
            outline-none
            text-gray-700
            placeholder-gray-400
          "
        ></textarea>
      </Field>
      <div>
        <ErrorMessage name="name" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <!--
      <div class="mb-3">
        <Field v-model="unit" name="unit" v-slot="{ field }">
          <select
            v-bind="field"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Seleccione"
          >
            <option value="">Seleccione unidad</option>
            <option value="m">m</option>
            <option value="m2">m2</option>
            <option value="m3">m3</option>
            <option value="und">und</option>
            <option value="puntos">puntos</option>
            <option value="HH">HH</option>
            <option value="kg">kg</option>
          </select>
        </Field>
        <div>
          <ErrorMessage name="unit" v-slot="{ message }">
            <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
          </ErrorMessage>
        </div>
      </div>
    -->

    <div class="mb-3">
      <template v-for="c in colors" :key="c">
        <button
          :class="c === color ? 'ring-2 ring-primary-500/50' : ''"
          class="... rounded-full h-4 w-4 mr-1"
          :style="{ 'background-color': c }"
          type="text"
          @click.prevent.stop="onSelectColor(c)"
        ></button>
      </template>
    </div>

    <div class="flex justify-between">
      <button
        type="submit"
        class="
          rounded
          bg-primary
          py-2
          px-4
          mr-2
          text-white text-sm
          font-medium
          flex
        "
        :disabled="loadingSave"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Guardar
      </button>

      <button
        type="button"
        class="mx-auto cursor-pointer p-1 rounded px-2 eee"
        :style="{
          'background-color': isToDispatch
            ? '#ffeb00 !important'
            : 'transparent',
          border: '1px solid #3565AE',
        }"
        @click.prevent.stop="isToDispatch = !isToDispatch"
      >
        <img src="@/assets/icons/truck.svg" :style="{ height: '12px' }" />
      </button>

      <button
        type="text"
        class="rounded py-2 text-white text-sm font-medium flex text-gray"
        @click.stop="onCloseForm"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
        >
          <path
            d="M13.4577 4.54025L12.4589 3.5415L8.49935 7.50109L4.53977 3.5415L3.54102 4.54025L7.5006 8.49984L3.54102 12.4594L4.53977 13.4582L8.49935 9.49859L12.4589 13.4582L13.4577 12.4594L9.4981 8.49984L13.4577 4.54025Z"
            fill="#717191"
          />
        </svg>
      </button>
    </div>
  </Form>
</template>
