<template>
  <Modal :open="open" @update:open="onClose">
    <template #body>
      <div>
        <img :src="plan.image" height="100" />
      </div>
      <div>
        <div v-for="(legend, i) in plan.legend" :key="i">
          <div class="grid grid-cols-5 pb-4">
            <div class="m-auto">
              <div
                class="rounded-full"
                :style="{
                  'background-color': legend.color,
                  opacity: 0.5,
                  width: '24px',
                  height: '24px',
                }"
              ></div>
            </div>
            <div class="col-span-3">
              <div class="">
                <p :style="{ color: legend.color }">{{ legend.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #buttonDelete>
      <button
        class="
          w-full
          inline-flex
          justify-center
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          bg-red-600
          text-base
          font-medium
          text-white
          hover:bg-red-700
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
          sm:ml-3 sm:w-auto sm:text-sm
        "
        :disabled="loadingDelete"
        @click="onDeletePlan()"
      >
        <LoadingButtonIcon v-if="loadingDelete" />
        Eliminar
      </button>
    </template>
  </Modal>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["update:open"]);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  plan: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const loadingDelete = ref(false);

const onClose = () => {
  emit("update:open", false);
};

const onDeletePlan = async () => {
  try {
    loadingDelete.value = true;

    await store.dispatch("deletePlanDiary", { key: props.plan.key });

    onClose();
  } catch (error) {
    console.error(error);
  } finally {
    loadingDelete.value = false;
  }
};
</script>
