<template>
  <div class="min-h-full">
    <nav
      class="tabs flex flex-row pt-5 border-b px-6 fixed bg-white w-full z-10"
    >
      <button
        class="
          tab
          active
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        :class="
          mode === 'week'
            ? 'font-medium border-b-4 border-blue-500 text-blue-500'
            : ''
        "
        @click="mode = 'week'"
      >
        Semanal
      </button>
      <button
        class="
          tab
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        :class="
          mode === 'day'
            ? 'font-medium border-b-4 border-blue-500 text-blue-500'
            : ''
        "
        @click="mode = 'day'"
      >
        Diaria
      </button>
    </nav>
    <div v-if="getWeekInitialDate" class="p-4 xl:w-full lg:w-screen pt-20">
      <div v-if="mode === 'week'">
        <WeeklyByDepartures
          v-if="
            [
              '5829c862-a149-4a03-9682-bb0e27a6b46f',
              'bfb7c78c-6573-452c-9dbd-ab2772851bf4',
              'c64fb811-f3f6-4160-8a34-9adb1ffdb3d6',
            ].includes(getWork.key)
          "
        />
        <Weekly v-else />
      </div>

      <div v-if="mode === 'day'">
        <DailyByDepartures
          v-if="
            [
              '5829c862-a149-4a03-9682-bb0e27a6b46f',
              'bfb7c78c-6573-452c-9dbd-ab2772851bf4',
              'c64fb811-f3f6-4160-8a34-9adb1ffdb3d6',
            ].includes(getWork.key)
          "
        />
        <Daily v-else />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import Daily from "./daily/Daily";
import Weekly from "./weekly/Weekly";
import WeeklyByDepartures from "./departures/weekly/Weekly";
import DailyByDepartures from "./departures/daily/Daily";

const store = useStore();

const mode = ref("week");

const getWork = computed(() => store.getters.getWork);
const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);

const init = async () => {
  if (!getWeekInitialDate.value)
    await store.dispatch("setCurrentWeekInitialDate");
};

init();

onUnmounted(() => {
  store.dispatch("resetMembers");
});
</script>
