<template>
  <Modal :open="open" width="w-1/3" @update:open="onClose">
    <template #body>
      <div class="w-full">
        <div class="pb-4">{{ props.schedule.name }}</div>
        <div class="col-span-2">
          <div v-if="getScheduleImagePreview" class="h-96">
            <img
              :src="getScheduleImagePreview"
              class="h-full bg-cover bg-center"
            />
          </div>
          <div>
            <input
              ref="scheduleImage"
              type="file"
              accept="image/png, image/jpeg"
              class="hidden"
              @change="onChangeImage"
            />
            <button
              class="
                text-primary
                border
                text-sm
                font-medium
                border-primary
                p-2
                mt-2
                px-3
                h-full
              "
              @click="$refs.scheduleImage.click()"
            >
              {{ props.schedule.image ? "Cambiar imagen" : "Subir imagen" }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <template #buttons>
      <button
        class="
          rounded
          bg-primary
          py-2
          px-5
          ml-2
          text-white
          font-medium
          text-sm
          flex
        "
        type="submit"
        :disabled="loading"
        @click="onSave"
      >
        <LoadingButtonIcon v-if="loading" />
        Guardar
      </button>
    </template>
  </Modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from "vue";
import { useStore } from "vuex";
import { uploadFileToStorage } from "@/services/storage";

const emit = defineEmits(["update:open"]);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  schedule: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const image = ref(null);
const loading = ref(false);

const getWork = computed(() => store.getters.getWork);
const getScheduleImagePreview = computed(() => {
  return image.value
    ? URL.createObjectURL(image.value)
    : props.schedule.image
    ? `${props.schedule.image}?${new Date().getTime()}`
    : "";
});

const onClose = () => {
  emit("update:open", false);
};

const onChangeImage = (event) => {
  image.value = event.target.files[0];
};

const onSave = async () => {
  try {
    loading.value = true;

    if (image.value) {
      const fileUrl = `/works/${getWork.value.key}/schedules/${
        props.schedule.key
      }/image.${image.value.name.split(".").pop()}`;

      await uploadFileToStorage(fileUrl, image.value);

      await store.dispatch("updateSchedule", {
        schedule: {
          key: props.schedule.key,
          image: fileUrl,
        },
      });

      image.value = null;

      onClose();
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};
</script>
