<template>
  <span
    class="
      relative
      bg-secondary
      text-white
      rounded-full
      p-2
      flex
      items-center
      justify-center
      group
      cursor-pointer
    "
    @mouseover="showTooltip"
    @mouseleave="hideTooltip"
  >
    {{ props.member.name.substring(0, 1)
    }}{{ (props.member.lastName || "").substring(0, 1) }}
    <slot name="buttonDelete"></slot>
    <div
      v-if="tooltipVisible"
      class="
        absolute
        left-0
        bottom-0
        mb-12
        bg-gray-200
        text-gray-900
        rounded
        p-2
        shadow-lg
        whitespace-nowrap
        overflow-hidden
        text-ellipsis
        max-w-xs
        z-10
      "
    >
      {{ props.member.lastName }} {{ props.member.lastName2 }}
      {{ props.member.name }}
    </div>
  </span>
</template>

<script setup>
import { defineProps, ref } from "vue";

const props = defineProps({
  member: {
    type: Object,
    required: true,
  },
});

const tooltipVisible = ref(false);
const tooltipMember = ref(null);

const showTooltip = () => {
  tooltipMember.value = props.member;
  tooltipVisible.value = true;
};

const hideTooltip = () => {
  tooltipVisible.value = false;
  tooltipMember.value = null;
};
</script>
