<template>
  <Form
    @submit="onHandleSchedule"
    :validation-schema="{
      name: 'required',
      metered: 'decimal',
    }"
  >
    <div
      class="grid grid-cols-12 gap-4 mb-1 pt-2"
      :style="{
        'background-color':
          schedule && schedule.status === 'Completed'
            ? '#c1e2c0'
            : isToDispatch
            ? '#fffde5'
            : '',
        height: '50px',
      }"
    >
      <div class="pt-2 col-span-1">
        <div v-if="action === 'update'">
          <input
            v-model="isSelected"
            :id="props.id + 1"
            type="checkbox"
            class="
              checked:bg-primary checked:border-transparent
              cursor-pointer
              ...
            "
            @click="onSelect(props.schedule)"
          />

          <label class="ml-4 text-secondary" :for="props.id + 1">
            {{ props.id + 1 }}
          </label>
        </div>
      </div>
      <div class="col-span-3">
        <div class="mb-4">
          <Field v-model="name" name="name" v-slot="{ field }">
            <textarea
              v-bind="field"
              class="
                h-8
                text-sm
                relative
                py-2
                px-2
                w-full
                bg-white
                overflow-hidden
                border
                text-secondary
              "
            ></textarea>
          </Field>
          <div>
            <ErrorMessage name="name" v-slot="{ message }">
              <p class="text-red-500 pt-1 text-xs">{{ message }}</p>
            </ErrorMessage>
          </div>
        </div>
      </div>

      <div class="col-span-2">
        <div>
          <Field v-model="departureKey" name="departureKey" v-slot="{ field }">
            <select
              v-bind="field"
              class="
                relative
                py-2
                px-2
                w-full
                bg-white
                rounded-sm
                border
                outline-none
                text-gray-700
                placeholder-gray-400
              "
              placeholder="Seleccione"
            >
              <option
                v-for="departure in getDepartures"
                :key="departure.key"
                :value="departure.key"
              >
                {{ departure.name }}
              </option>
            </select>
          </Field>
        </div>
        <div>
          <ErrorMessage name="departureKey" v-slot="{ message }">
            <p class="text-red-500 pt-1 text-xs">{{ message }}</p>
          </ErrorMessage>
        </div>
      </div>

      <div class="col-span-1">
        <Field v-model="metered" name="metered" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-1
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Ingrese metrado"
          />
        </Field>
        <div>
          <ErrorMessage name="metered" v-slot="{ message }">
            <p class="text-red-500 pt-1 text-xs">{{ message }}</p>
          </ErrorMessage>
        </div>
      </div>

      <div class="col-span-1">
        <Field v-model="unit" name="unit" v-slot="{ field }">
          <select
            v-bind="field"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Seleccione"
          >
            <option value="">Seleccione unidad</option>
            <option value="m">m</option>
            <option value="m2">m2</option>
            <option value="m3">m3</option>
            <option value="und">und</option>
            <option value="puntos">puntos</option>
            <option value="HH">HH</option>
            <option value="kg">kg</option>
          </select>
        </Field>
        <div>
          <ErrorMessage name="unit" v-slot="{ message }">
            <p class="text-red-500 pt-1 text-xs">{{ message }}</p>
          </ErrorMessage>
        </div>
      </div>

      <div class="col-span-2">
        <div>
          <Field v-model="userKey" name="userKey" v-slot="{ field }">
            <select
              v-bind="field"
              class="
                relative
                py-2
                px-2
                w-full
                bg-white
                rounded-sm
                border
                outline-none
                text-gray-700
                placeholder-gray-400
              "
              placeholder="Seleccione"
            >
              <option
                v-for="member in getMembers"
                :key="member.key"
                :value="member.key"
              >
                {{ member.lastName }} {{ member.lastName2 }} {{ member.name }}
              </option>
            </select>
          </Field>
        </div>
        <div>
          <ErrorMessage name="userKey" v-slot="{ message }">
            <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
          </ErrorMessage>
        </div>
      </div>

      <div class="col-span-1">
        <div class="text-center">
          <div
            class="
              relative
              p-1
              bg-white
              rounded
              border
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none
            "
          >
            <div class="flex">
              <select
                v-model="hour"
                name="hours"
                class="bg-transparent appearance-none outline-none"
              >
                <option value="">-</option>
                <option value="00">00</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="11">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
              </select>
              <span class="mx-1">:</span>
              <select
                v-model="minute"
                name="minutes"
                class="bg-transparent appearance-none outline-none mr-4"
              >
                <option value="">-</option>
                <option value="00">00</option>
                <option value="30">30</option>
              </select>
              <!-- <select
                v-model="time"
                name="ampm"
                class="bg-transparent appearance-none outline-none"
              >
                <option value="">-</option>
                <option value="am">AM</option>
                <option value="pm">PM</option>
              </select> -->
            </div>
          </div>
        </div>
        <div>
          <ErrorMessage name="hour" v-slot="{ message }">
            <p class="text-red-500 pt-1 text-xs">{{ message }}</p>
          </ErrorMessage>
        </div>
      </div>

      <div v-if="allowHandleSchedule" class="flex justify-end col-span-1">
        <button
          class="
            rounded
            bg-primary
            pt-2
            px-2
            text-white
            font-medium
            text-sm
            h-8
            flex
          "
          type="submit"
          :disabled="loadingSave"
        >
          <LoadingButtonIcon v-if="loadingSave" />
          Guardar
        </button>
      </div>
      <div
        v-else-if="action === 'update'"
        class="flex justify-end pb-5 col-span-1"
      >
        <button
          type="text"
          :style="{
            border: '1px solid #0057FF',
          }"
          class="rounded px-1 cursor-pointer"
          @click.prevent.stop="onOpenModalScheduleImage"
        >
          <img src="@/assets/icons/plane.svg" :style="{ height: '12px' }" />
        </button>

        <button
          type="text"
          class="mx-auto cursor-pointer p-1 rounded"
          :style="{
            'background-color': schedule.isToDispatch
              ? '#ffeb00 !important'
              : 'transparent',
            border: '1px solid #0057FF',
          }"
          @click.prevent.stop="onToggleDispatch(schedule)"
        >
          <LoadingButtonIcon
            v-if="loadingEditDispatch && scheduleSelected.key === schedule.key"
            color="gray"
            size="12px"
            :has-margin="false"
          />
          <img
            v-else
            src="@/assets/icons/truck.svg"
            :style="{ height: '12px' }"
          />
        </button>

        <button type="text" @click.stop="onOpenModalDeleteSchedule">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56976C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56976V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z"
              fill="#E00F57"
            />
          </svg>
        </button>
      </div>
    </div>
  </Form>

  <Modal
    :open="modalDeleteSchedule"
    @update:open="modalDeleteSchedule = $event"
  >
    <template #body>
      <span v-if="scheduleSelected" class="font-medium">
        ¿Desea eliminar la actividad "{{ scheduleSelected.name }}"?
      </span>
    </template>
    <template #buttonDelete>
      <button
        type="button"
        class="
          w-full
          inline-flex
          justify-center
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          bg-red-600
          text-base
          font-medium
          text-white
          hover:bg-red-700
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
          sm:ml-3 sm:w-auto sm:text-sm
        "
        :disabled="loadingDelete"
        @click="onDeleteSchedule()"
      >
        <LoadingButtonIcon v-if="loadingDelete" />
        Eliminar
      </button>
    </template>
  </Modal>

  <ScheduleImage
    v-if="modalScheduleImage"
    :open="modalScheduleImage"
    @update:open="modalScheduleImage = $event"
    :schedule="props.schedule"
  />
</template>

<script setup>
import { computed, watch, defineProps, defineEmits, ref } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";
import dayjs from "dayjs";
import ScheduleImage from "./modals/ScheduleImage";

const emit = defineEmits([
  "update:allowCreate",
  "onSelectRow",
  "update:schedule",
]);

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  scheduleSelected: {
    type: Array,
    default: () => [],
  },
  action: {
    type: String,
    default: "update",
  },
  schedule: {
    type: Object,
    default: () => ({}),
  },
  day: {
    type: String,
    default: "",
  },
  allowCreate: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const { value: name } = useField("name");
const { value: departureKey } = useField("departureKey");
const { value: userKey } = useField("userKey");
const { value: unit } = useField("unit");
const { value: metered } = useField("metered");

const hour = ref("");
const minute = ref("");
const loadingSave = ref(false);
const isToDispatch = ref(false);
const isSelected = ref(false);
const loadingEditDispatch = ref(false);
const loadingDelete = ref(false);
const modalDeleteSchedule = ref(false);
const scheduleSelected = ref(null);
const modalScheduleImage = ref(false);

const getDepartures = computed(() => store.getters.getDepartures);
const getMembers = computed(() =>
  store.getters.getMembers.filter((member) => member.userEntity === "User")
);
const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);
const getDaySchedule = computed(() => store.getters.getDaySchedule);
const allowHandleSchedule = computed(() => {
  return (
    name.value !== props.schedule?.name ||
    departureKey.value !== props.schedule?.departure?.key ||
    userKey.value !== props.schedule?.user?.key ||
    (hour.value && hour.value !== props.schedule?.hour?.substring(0, 2)) ||
    (minute.value && minute.value !== props.schedule?.hour?.substring(3, 5)) ||
    unit.value !== props.schedule?.unit ||
    metered.value != props.schedule?.metered
  );
});

watch(
  () => props.isSelected,
  (currentValue) => (isSelected.value = currentValue)
);

watch(
  () => props.scheduleSelected,
  (currentValue) => {
    if (!currentValue.find((c) => c.key == props.schedule.key))
      isSelected.value = false;
  }
);

const init = () => {
  name.value = props.schedule?.name;
  departureKey.value = props.schedule?.departure?.key;
  userKey.value = props.schedule?.user?.key;
  hour.value = props.schedule?.hour?.substring(0, 2) || "";
  minute.value = props.schedule?.hour?.substring(3, 5) || "";
  isToDispatch.value = props.schedule?.isToDispatch || false;
  unit.value = props.schedule?.unit;
  metered.value = props.schedule?.metered;
};

init();

const getInitialDateByDay = (day) => {
  const date = new Date(day);
  date.setDate(date.getDate() - (date.getDay() - 1));
  return dayjs(date).format("YYYY-MM-DD");
};

const onSelect = (schedule) => {
  emit("onSelectRow", schedule);
};

const onHandleSchedule = async () => {
  try {
    loadingSave.value = true;

    let schedule = {
      name: name.value,
      departureKey: departureKey.value,
      userKey: userKey.value,
      hour:
        hour.value && minute.value ? `${hour.value}:${minute.value}:00` : "",
      isToDispatch: isToDispatch.value,
      metered: metered.value,
      unit: unit.value,
    };

    if (props.allowCreate) {
      let weekKey;
      if (!getDaySchedule.value?.length)
        weekKey = await store.dispatch("getWeekKeyByDate", { date: props.day });
      else weekKey = getDaySchedule.value[0].weekKey;

      const isTimeout =
        getInitialDateByDay(props.day) === getWeekInitialDate.value;

      schedule = await store.dispatch("createSchedule", {
        schedule: {
          ...schedule,
          type: "Daily",
          weekKey: weekKey,
          date: props.day,
          status: isTimeout ? "RegisteredAfterDate" : "Registered",
          timeout: isTimeout,
        },
      });

      emit("update:allowCreate", false);
    } else {
      schedule = await store.dispatch("updateSchedule", {
        schedule: {
          ...schedule,
          key: props.schedule.key,
        },
      });
    }

    emit("update:schedule", schedule);
  } catch (error) {
    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};

const onOpenModalDeleteSchedule = () => {
  scheduleSelected.value = props.schedule;
  modalDeleteSchedule.value = true;
};

const onOpenModalScheduleImage = () => {
  modalScheduleImage.value = true;
};

const onToggleDispatch = async (schedule) => {
  try {
    scheduleSelected.value = schedule;
    loadingEditDispatch.value = true;

    await store.dispatch("updateSchedule", {
      schedule: {
        key: schedule.key,
        isToDispatch: !isToDispatch.value,
      },
    });

    isToDispatch.value = !isToDispatch.value;

    scheduleSelected.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingEditDispatch.value = false;
  }
};

const onDeleteSchedule = async () => {
  try {
    loadingDelete.value = true;

    await store.dispatch("deleteSchedule", { key: props.schedule.key });

    modalDeleteSchedule.value = false;
    scheduleSelected.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingDelete.value = false;
  }
};
</script>

<style scoped>
input[type="checkbox"] + label:before {
  content: "";
  width: 25px;
  height: 25px;
  float: left;
  /* margin: 0.5em 0.5em 0 0; */
  border: 1px solid #ccc;
  background: #fff;
}

input[type="checkbox"]:checked + label:before {
  border-color: #0057ff;
}
input[type="checkbox"]:checked + label:after {
  content: "";
  width: 15px;
  height: 6px;
  border: 2px solid #0057ff;
  float: left;
  margin-left: -18.96px;
  margin-top: 8px;
  border-right: 0;
  border-top: 0;
  /* margin-top: 1em; */
  transform: rotate(-55deg);
}

input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label {
  font-weight: bold;
  color: #ccc;
}
input[type="checkbox"]:checked + label {
  color: #0057ff;
}
</style>
