<template>
  <div class="flex pt-5">
    <Form
      @submit="onAddColumn"
      :validation-schema="{
        name: 'required',
      }"
      class="flex"
    >
      <div class="relative w-80">
        <Field v-model="name" name="name" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:shadow-outline
            "
            placeholder="Nueva columna"
            autocomplete="off"
            @input="filterSuggestions"
          />
        </Field>
        <div
          v-show="filteredSuggestions.length > 0 && showSuggestions"
          class="absolute w-full bg-white border rounded-sm mt-1"
          :style="{
            'max-height': '240px',
            'z-index': 1,
            'overflow-y': 'auto',
          }"
        >
          <ul>
            <li
              v-for="suggestion in filteredSuggestions"
              :key="suggestion"
              @click="selectSuggestion(suggestion)"
              class="py-2 px-2 hover:bg-gray-100 cursor-pointer"
            >
              {{ suggestion.name }}
            </li>
          </ul>
        </div>
      </div>

      <button
        class="rounded bg-primary py-2 px-8 text-white font-medium flex ml-2"
        type="submit"
        :disabled="loadingCreate"
      >
        <LoadingButtonIcon v-if="loadingCreate" />
        Agregar
      </button>

      <button
        v-if="!getWeekDepartures || !getWeekDepartures.length"
        class="
          text-primary
          border
          text-sm
          font-medium
          border-primary
          p-2
          px-2
          ml-2
        "
        type="submit"
        :style="{ width: '200px !important' }"
        :disabled="loadingGenerate"
        @click="onActiveModeDraft"
      >
        <LoadingButtonIcon v-if="loadingGenerate" />
        Copiar semana anterior
      </button>
    </Form>
  </div>
  <div
    v-if="loadingWeekDepartures"
    class="flex mt-2 h-5/6 pt-6 overflow-x-scroll"
  >
    <draggable
      :list="weekDepartures"
      class="flex min-h-screen"
      group="columns"
      item-key="departureKey"
      @change="onMoveColumn"
    >
      <template #item="{ element: weekDeparture, index }">
        <div class="border-r flex-1" :key="weekDeparture.departureKey">
          <div class="border-r flex-1" style="width: 200px">
            <div :id="weekDeparture.departureKey" class="px-2">
              <DayList
                :ind="index"
                :initial-date="weekDeparture.initialDate"
                :departure-key="weekDeparture.departureKey"
                :week-key="props.weekKey"
                :week-departure-key="weekDeparture.key"
                :is-current-week="
                  weekDeparture.initialDate === getWeekInitialDate
                "
                :schedule-selected="props.scheduleSelected"
                @onSelectSchedule="onSelectSchedule"
              />
            </div>
          </div>
        </div>
      </template>
    </draggable>

    <div style="width: 200px">
      <div class="flex mb-4">
        <div class="flex justify-center w-80">
          <p class="cursor-pointer">Casas terminadas</p>
        </div>
      </div>

      <div v-if="getMarkedSchedules">
        <div
          v-for="markedSchedule in getMarkedSchedules"
          :key="markedSchedule.key"
          class="flex justify-center w-full px-2"
        >
          <div
            class="
              border
              p-1
              mb-4
              text-sm
              rounded
              p-3
              relative
              cursor-pointer
              w-full
            "
            :class="
              markedSchedule.status === 'Registered'
                ? 'bg-white'
                : markedSchedule.status === 'Completed'
                ? 'bg-purple text-white'
                : 'hover:border-primary hover:bg-sky'
            "
          >
            <div style="min-height: 66px">
              <div v-if="markedSchedule.sector">
                {{ markedSchedule.sector.name }}
              </div>
              <label>{{ markedSchedule.name }}</label>
              <div v-if="markedSchedule.metered">
                {{ markedSchedule.metered }}{{ markedSchedule.unit }}
              </div>
              <div class="mt-2">
                Fecha de culminación: {{ formatDate(markedSchedule.markedAt) }}
              </div>
              <div
                v-if="markedSchedule.user || markedSchedule.users"
                class="flex flex-wrap gap-2 mt-2"
              >
                <Avatar
                  v-if="markedSchedule.user"
                  :member="markedSchedule.user"
                />
                <span
                  v-for="user in markedSchedule.users || []"
                  :key="user.key"
                >
                  <Avatar :member="user" />
                </span>
              </div>
              <div v-if="markedSchedule.isToDispatch" class="text-right">
                <button class="mx-auto p-1 absolute bottom-0 right-5">
                  <IconDispatch
                    :color="
                      markedSchedule.status === 'Completed'
                        ? '#FFFFFF'
                        : undefined
                    "
                  />
                </button>
              </div>

              <button
                :disabled="loadingUnmark"
                class="
                  absolute
                  bottom-0
                  right-0
                  p-1
                  hover:bg-white hover:text-primary
                "
                @click="onUnmark(markedSchedule)"
              >
                <svg viewBox="0 0 24 24" class="h-4 w-4" fill="none">
                  <path
                    d="M10 19L3 12L10 5V10H21V14H10V19Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";
import draggable from "vuedraggable";
import dayjs from "dayjs";
import DayList from "./DayList";
import Avatar from "@/components/inc/Avatar";
import IconDispatch from "@/components/icons/Dispatch";

const emit = defineEmits(["onSelectSchedule", "onActiveModeDraft"]);

const props = defineProps({
  day: {
    type: String,
    required: true,
  },
  weekKey: {
    type: String,
    required: true,
  },
  initialDate: {
    type: String,
    required: true,
  },
  scheduleSelected: {
    type: Array,
  },
});

const store = useStore();

const { value: name } = useField("name");

const loadingWeekDays = ref(false);
const loadingWeekDepartures = ref(false);
const loadingCreate = ref(false);
const loadingGenerate = ref(false);
const loadingMove = ref(false);
const loadingUnmark = ref(false);
const filteredSuggestions = ref([]);
const showSuggestions = ref(false);
const weekDepartures = ref([]);

const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);
const getDepartures = computed(() => store.getters.getDepartures);
const getWeekDepartures = computed(() => store.getters.getWeekDepartures);
const getMarkedSchedules = computed(() => store.getters.getMarkedSchedules);
// const getWeekDepartures = computed({
//   get: () => weekDepartures.value,
//   set: (newValue) => {
//     weekDepartures.value = newValue;
//   },
// });

const loadWeekDays = async (date) => {
  loadingWeekDays.value = false;
  loadingWeekDepartures.value = false;

  await store.dispatch("getAllWeekDepartures", { initialDate: date });

  loadingWeekDays.value = true;
  loadingWeekDepartures.value = true;
};

watch(
  () => props.initialDate,
  (value) => loadWeekDays(value)
);

watch(
  () => getWeekDepartures.value,
  (value) => {
    weekDepartures.value = value.sort((a, b) => {
      const aPriority = a.sort || 0;
      const bPriority = b.sort || 0;

      return aPriority - bPriority;
    });
  },
  { deep: true }
);

const init = () => {
  loadWeekDays(props.initialDate);

  if (!getMarkedSchedules.value) store.dispatch("getAllMarkedSchedules");
};

init();

const formatDate = (dateString) => {
  return dayjs(dateString).format("DD/MM/YYYY");
};

const filterSuggestions = () => {
  if (!name.value) {
    filteredSuggestions.value = [];
    showSuggestions.value = false;
  } else {
    filteredSuggestions.value = getDepartures.value.filter(
      (d) =>
        d.name.toLowerCase().includes(name.value.toLowerCase()) &&
        !getWeekDepartures.value.find((wd) => wd.departureKey === d.key)
    );
    showSuggestions.value = true;
  }
};

const selectSuggestion = (suggestion) => {
  name.value = suggestion.name;
  showSuggestions.value = false;
};

const onSelectSchedule = (schedule) => {
  emit("onSelectSchedule", schedule);
};

const onActiveModeDraft = () => {
  emit("onActiveModeDraft");
};

const onAddColumn = async (values, { resetForm }) => {
  try {
    loadingCreate.value = true;

    let departureKey;

    const departure = getDepartures.value.find((d) =>
      d.name.toLowerCase().includes(name.value.toLowerCase())
    );

    if (departure) {
      departureKey = departure.key;
    } else {
      const response = await store.dispatch("createDeparture", {
        departure: { name: name.value },
      });
      departureKey = response.key;
    }

    await store.dispatch("createWeekDeparture", {
      weekDeparture: {
        initialDate: props.initialDate,
        sort: getWeekDepartures.value.length,
        departureKey,
      },
    });

    resetForm();
    emit("onComplete");
  } catch (error) {
    console.error(error);
  } finally {
    loadingCreate.value = false;
  }
};

const onMoveColumn = async (event) => {
  try {
    loadingMove.value = true;

    if (event.removed) {
      const weekDepartures = getWeekDepartures.value
        .filter(
          (schedule) =>
            schedule.departureKey === event.removed.element.departureKey &&
            schedule.key !== event.removed.element.key
        )
        .sort((a, b) => {
          const aPriority = a.sort || 0;
          const bPriority = b.sort || 0;

          return aPriority - bPriority;
        });

      const promises = [];

      for (let i = event.removed.oldIndex; i < weekDepartures.length; i++) {
        promises.push(
          store.dispatch("updateWeekDeparture", {
            weekDeparture: {
              key: weekDepartures[i].key,
              sort: i,
            },
          })
        );
      }

      await Promise.all(promises);
    }

    if (event.moved) {
      const weekDepartures = getWeekDepartures.value.sort((a, b) => {
        const aPriority = a.sort || 0;
        const bPriority = b.sort || 0;

        return aPriority - bPriority;
      });

      const promises = [];

      if (event.moved.oldIndex < event.moved.newIndex) {
        for (let i = event.moved.oldIndex + 1; i <= event.moved.newIndex; i++) {
          promises.push(
            store.dispatch("updateWeekDeparture", {
              weekDeparture: {
                key: weekDepartures[i].key,
                sort: i - 1,
              },
            })
          );
        }

        promises.push(
          store.dispatch("updateWeekDeparture", {
            weekDeparture: {
              key: weekDepartures[event.moved.oldIndex].key,
              sort: event.moved.newIndex,
            },
          })
        );
      }

      if (event.moved.oldIndex > event.moved.newIndex) {
        for (let i = event.moved.newIndex; i < event.moved.oldIndex; i++) {
          promises.push(
            store.dispatch("updateWeekDeparture", {
              weekDeparture: {
                key: weekDepartures[i].key,
                sort: i + 1,
              },
            })
          );
        }

        promises.push(
          store.dispatch("updateWeekDeparture", {
            weekDeparture: {
              key: weekDepartures[event.moved.oldIndex].key,
              sort: event.moved.newIndex,
            },
          })
        );
      }

      await Promise.all(promises);
    }
  } catch (error) {
    console.error(error);
  } finally {
    loadingMove.value = false;
  }
};

const onUnmark = async (markedSchedule) => {
  try {
    loadingUnmark.value = true;

    await store.dispatch("deleteMarkedSchedule", {
      key: markedSchedule.key,
      initialDate: props.initialDate,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingUnmark.value = false;
  }
};
</script>

<style>
.ghost {
  opacity: 0.5;
}
</style>
