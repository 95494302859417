<template>
  <div>
    <PlanePanel
      v-if="showPlanePanel"
      :date="day"
      @onComplete="showPlanePanel = false"
    />

    <div v-show="!showPlanePanel">
      <div class="flex justify-between">
        <div class="flex w-full">
          <button
            class="
              text-primary
              border
              text-sm
              font-medium
              border-primary
              p-2
              px-6
            "
            @click="day = today"
          >
            Día Actual
          </button>

          <input
            v-model="day"
            type="date"
            class="
              mx-5
              relative
              p-2
              bg-white
              rounded
              border
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none
              px-6
            "
          />
        </div>
        <div class="flex w-full justify-end">
          <button
            class="
              text-primary
              border
              text-sm
              font-medium
              border-primary
              p-2
              mx-2
              px-6
            "
            @click="showPlanePanel = true"
          >
            Agregar planos
          </button>

          <button
            class="
              text-white
              border
              text-sm
              font-medium
              bg-greenDark
              border-greenDark
              py-2
              px-5 px-6
              mx-2
            "
          >
            Exportar
          </button>
        </div>
      </div>

      <div v-if="day && getDaySchedule" class="pt-8">
        <div class="flex py-5">
          <h2 class="font-medium text-secondary">
            {{ weekDays[new Date(day).getDay()] }}
          </h2>
          <button
            v-if="!allowCreate"
            class="
              text-primary
              border
              text-sm
              font-medium
              border-primary
              px-1
              ml-2
              rounded-full
              w-6
              h-6
            "
            @click="allowCreate = true"
          >
            +
          </button>
        </div>
        <div>
          <div class="grid grid-cols-12 gap-4 ml-8">
            <div class="col-span-1"></div>
            <div class="col-span-3">Nombre de Actividad</div>
            <div class="col-span-2">Tipo</div>
            <div class="col-span-1">Metrado</div>
            <div class="col-span-1">Unidad</div>
            <div class="col-span-2">Responsable</div>
            <div class="col-span-1">Hora de inicio</div>
            <div class="col-span-2"></div>
          </div>

          <draggable
            :list="schedules || []"
            class="list-group"
            tag="ul"
            handle=".handle"
            ghost-class="ghost"
            item-key="id"
          >
            <template #item="{ element, index }">
              <li class="flex">
                <i class="fa fa-align-justify handle w-10 h-10 pt-4">
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M5 14H19M5 10H19"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </g>
                  </svg>
                </i>

                <!-- <div v-for="(daySchedule, dayInd) in getDaySchedule" :key="daySchedule.key"> -->
                <RowSchedule
                  :id="index"
                  :schedule="element"
                  :schedule-selected="scheduleSelected"
                  @update:schedule="updateSchedule($event)"
                  @onSelectRow="onSelectRow"
                ></RowSchedule>
                <!-- </div> -->
              </li>
            </template>
          </draggable>
        </div>

        <div v-if="allowCreate">
          <RowSchedule
            action="create"
            :day="day"
            :allow-create="allowCreate"
            @update:schedule="updateSchedule($event)"
            @update:allow-create="allowCreate = $event"
          />
        </div>

        <div
          v-for="restriction in restrictions"
          :key="restriction.key"
          class="grid grid-cols-12 gap-4 py-2 mb-3 ml-8 text-sm bg-red-100"
        >
          <div class="col-span-1"></div>
          <div class="col-span-3">{{ restriction.name }}</div>
          <div class="col-span-3">Restricción</div>
          <div class="col-span-2">
            {{ restriction.user.lastName }} {{ restriction.user.lastName2 }}
            {{ restriction.user.name }}
          </div>
          <div class="col-span-3"></div>
        </div>
      </div>

      <div class="mt-10 flex">
        <div v-for="plan in getPlansDiaries" :key="plan.key">
          <img
            :src="plan.image"
            width="160"
            height="100"
            class="border border-black-100 mr-3 cursor-pointer"
            @click="onViewPlan(plan)"
          />
        </div>
      </div>
    </div>

    <PlaneViewer
      v-if="modalPlaneViewer"
      :open="modalPlaneViewer"
      @update:open="modalPlaneViewer = $event"
      :plan="planSelected"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import draggable from "vuedraggable";
import RowSchedule from "./components/RowSchedule";
import PlanePanel from "./components/PlanePanel";
import PlaneViewer from "./components/modals/PlaneViewer.vue";

const store = useStore();

const today = ref(null);
const day = ref(null);
const weekDays = ref([
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
]);
const allowCreate = ref(false);
const showPlanePanel = ref(false);
const scheduleSelected = ref([]);
const modalPlaneViewer = ref(false);
const planSelected = ref(null);
const schedules = ref([]);
const restrictions = ref([]);

const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);
const getTypesRestriction = computed(() => store.getters.getTypesRestriction);
const getDaySchedule = computed(() => store.getters.getDaySchedule);
const getDepartures = computed(() => store.getters.getDepartures);
const getMembers = computed(() => store.getters.getMembers);
const getPlansDiaries = computed(() => store.getters.getPlansDiaries);

watch(
  () => day.value,
  (currentDay, prevDay) => {
    if (currentDay !== prevDay && currentDay) {
      loadScheduleByDay(currentDay);
    }
  }
);

watch(
  () => getDaySchedule.value,
  (current) => {
    if (!current) return;

    schedules.value = [...current?.map((ds) => ({ ...ds }))];
  }
);

const loadScheduleByDay = async (day) => {
  await store.dispatch("findScheduleByDay", { date: day });
  await store.dispatch("getAllPlansDiaries", { date: day });

  schedules.value = [...getDaySchedule.value.map((ds) => ({ ...ds }))];

  restrictions.value = await store.dispatch("getAllRestrictions", {
    liftingDateFrom: day,
  });
};

const init = async () => {
  const date = new Date();
  if (date.getDay() === 7) {
    day.value = getWeekInitialDate.value;
  } else {
    today.value = dayjs(date).format("YYYY-MM-DD");
    day.value = today.value;
  }

  await loadScheduleByDay(day.value);

  if (!getMembers.value)
    await store.dispatch("getAllMembers", { type: ["Worker", "Admin"] });
  if (!getDepartures.value) await store.dispatch("getAllDepartures");
  if (!getTypesRestriction.value)
    await store.dispatch("getAllTypes", { type: "Restriction" });
};

init();

const updateSchedule = (value) => {
  const scheduleInd = schedules.value.findIndex((sch) => sch.key === value.key);
  if (scheduleInd >= 0) schedules.value[scheduleInd] = value;
  else schedules.value.push(value);
};

const onSelectRow = (schedule) => {
  const ind = scheduleSelected.value.findIndex(
    (sch) => sch.key === schedule.key
  );
  if (ind >= 0) {
    scheduleSelected.value.splice(ind, 1);
  } else {
    scheduleSelected.value.push(schedule);
  }
};

const onViewPlan = (plan) => {
  planSelected.value = { ...plan };
  modalPlaneViewer.value = true;
};
</script>
