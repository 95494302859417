<template>
  <div v-if="getPlaneSpecialities">
    <div class="bg-gray-100 flex justify-between p-4">
      <div class="flex">
        <div class="mr-4">
          <label>Especialidad</label>
          <select
            v-model="specialityKey"
            class="
              relative
              py-3
              px-2
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
              w-full
            "
            placeholder="Seleccione"
          >
            <option
              v-for="speciality in getPlaneSpecialities"
              :key="speciality"
              :value="speciality.key"
            >
              {{ speciality.name }}
            </option>
          </select>
        </div>
        <div class="mr-4">
          <label>Plano</label>
          <select
            v-model="planKey"
            class="
              relative
              py-3
              px-2
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
              w-full
            "
            placeholder="Seleccione"
          >
            <option value=""></option>
            <option v-for="plan in plans" :key="plan.key" :value="plan.key">
              {{ plan.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="my-auto">
        <button
          class="
            text-primary
            border
            text-sm
            font-medium
            border-primary
            p-2
            px-6
          "
          @click="emit('onComplete')"
        >
          Cerrar
        </button>
      </div>
    </div>

    <div v-if="planKey" class="pt-4">
      <Canva
        :image-url="imageUrl"
        :speciality-key="specialityKey"
        :date="props.date"
        @onComplete="emit('onComplete')"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import Canva from "./planepanel/Canva";

const props = defineProps({
  date: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["onComplete"]);

const store = useStore();

const imageUrl = ref(null);

const specialityKey = ref(null);
const planKey = ref(null);
const plans = ref([]);

const getPlaneSpecialities = computed(() => store.getters.getPlaneSpecialities);

watch(
  () => specialityKey.value,
  async (currentValue) => {
    planKey.value = "";
    plans.value = await store.dispatch("getAllPlans", {
      specialityKey: currentValue,
      resetPlans: false,
    });
  }
);

watch(
  () => planKey.value,
  (currentValue) => {
    if (currentValue) {
      const plan = plans.value.find((p) => p.key === currentValue);
      imageUrl.value =
        plan.convertedImage ||
        "https://s3-alpha-sig.figma.com/img/2b09/b9bf/4e92ff084cb99a1dbeedc28263c5cf47?Expires=1644192000&Signature=ZAL6qLQzh2lCjR-FFiR25YIxffDbbkS7RefHzw3Ddb6Van0yrJClXXsL5hJMUV3rROFrhTVN91C265unlWrp~ixsNNZ-LAMqrzlgJx5bp4rkhbmYbDeimMpPI7xxyGgs330UqAKZZ-SlBk~f60fAwgaXF1WWW8uz6Gwxam-1LGUB90TJrP4OJTmKn9r~LO0T3hwNvGu0J2Tsi30Jqmw74yvp8HGmMtynA4Tbdq82qeK8SfbnjG2eosvKO2drqJ1Z1rC12A1WTZGNVglbaxCeEVTWG4iV6TNcBBCa7IwakC00T0uJCv6ZNwVSWLuOML21FiDPkH~r0FDM~83IvaJMUw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA";
    }
  }
);

const init = async () => {
  if (!getPlaneSpecialities.value)
    await store.dispatch("getAllSpecialities", { type: "Plane" });
};

init();
</script>
