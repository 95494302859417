<script setup>
import { defineEmits, defineProps, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";
import draggable from "vuedraggable";
import ScheduleForm from "./ScheduleForm";
import Avatar from "@/components/inc/Avatar";
import IconDispatch from "@/components/icons/Dispatch";
import UpdateWeekDepartureModal from "./modals/UpdateWeekDeparture";
import UpdateWeekDepartureUserModal from "./modals/UpdateWeekDepartureUser";
import DeleteWeekDepartureModal from "./modals/DeleteWeekDeparture";

const emit = defineEmits(["onSelectSchedule"]);

const props = defineProps({
  weekDepartureKey: {
    type: String,
    required: true,
  },
  date: {
    type: String,
  },
  initialDate: {
    type: String,
    required: true,
  },
  ind: {
    type: Number,
    required: true,
  },
  departureKey: {
    type: String,
    required: true,
  },
  weekKey: {
    type: String,
    required: true,
  },
  isCurrentWeek: {
    type: Boolean,
    required: true,
  },
  scheduleSelected: {
    type: Array,
  },
});

const store = useStore();

const { value: sectorKey } = useField("sectorKey");

const schedules = ref([]);
// const availableSectors = ref([]);
const restrictions = ref([]);
const allowCreate = ref(false);
const openOptions = ref(false);
const searchTerm = ref(null);
const showOptions = ref("");
const filteredSectors = ref([]);

const colors = ref([
  "#B6D7A8",
  "#A2C4C9",
  "#D5A6BD",
  "#F6B26A",
  "#BDBDBD",
  "#0AFF00",
  "#00FFFF",
  "#4A86E8",
  "#FF01FF",
  "#9900FF",
]);
const selectedMembers = ref([]);
const availableMembers = ref([]);
const scheduleToEdit = ref(null);
const scheduleToDelete = ref(null);
const weekDepartureSelected = ref(null);
const loadingEdit = ref(false);
const loadingClone = ref(false);
const loadingMark = ref(false);
const loadingDelete = ref(false);
const modalDeleteSchedule = ref(false);
const modalUpdateWeekDepartureUser = ref(false);
const modalDeleteWeekDeparture = ref(false);
const modalUpdateWeekDeparture = ref(false);

const getSchedule = computed(() => store.getters.getSchedule);
const getSchedulesCloned = computed(() => store.getters.getSchedulesCloned);
const getMembers = computed(() => store.getters.getMembers);
const getDeparturesMap = computed(() => store.getters.getDeparturesMap);
const getSectors = computed(() => store.getters.getSectors);
const getWeekDepartures = computed(() => store.getters.getWeekDepartures);

watch(
  () => getSchedulesCloned.value,
  (currentValue) => {
    let last = currentValue[currentValue.length - 1];
    if (last.departure.key === props.departureKey)
      onCompleteCreateSchedule(last);
  }
);

watch(
  () => getSchedule.value,
  (currentValue) => {
    if (!currentValue) return;

    let currentSchedule = currentValue.schedule;
    let schedulesFound = currentSchedule.filter((sch) =>
      schedules.value.find((s) => s.key === sch.key)
    );
    schedulesFound.forEach((schedule) => {
      let scheduleInd = schedules.value.findIndex(
        (sch) => sch.key === schedule.key
      );
      if (scheduleInd >= 0) {
        schedules.value[scheduleInd] = { ...schedule };
      }
    });

    let schedulesDeleted = schedules.value.filter(
      (sch) => !currentSchedule.find((s) => s.key === sch.key)
    );
    schedulesDeleted.forEach((schedule) => {
      let scheduleInd = schedules.value.findIndex(
        (sch) => sch.key === schedule.key
      );
      if (scheduleInd >= 0) {
        schedules.value.splice(scheduleInd, 1);
      }
    });

    let schedulesAdded = currentSchedule.filter(
      (sch) =>
        !schedules.value.find((s) => s.key === sch.key) &&
        sch.departure.key === props.departureKey &&
        sch.isUnmarked
    );
    if (schedulesAdded.length) {
      let combinedSchedules = [...schedules.value, ...schedulesAdded];

      combinedSchedules.sort((a, b) => a.sort - b.sort);

      let resultSchedules = [];
      let sortMap = new Map();

      for (let schedule of combinedSchedules) {
        if (sortMap.has(schedule.sort)) {
          let index = resultSchedules.findIndex(
            (sch) => sch.sort === schedule.sort
          );
          resultSchedules.splice(index + 1, 0, schedule);
        } else {
          sortMap.set(schedule.sort, true);
          resultSchedules.push(schedule);
        }
      }

      schedules.value = [...resultSchedules];
    }
  },
  { deep: true }
);

watch(
  () => searchTerm.value,
  (value) => {
    const sector = getSectors.value.find((s) => s.name === value);
    if (!sector) sectorKey.value = null;
  }
);

const updateAvailableMembers = () => {
  availableMembers.value = getMembers.value.filter(
    (m) => !selectedMembers.value.find((sm) => sm.key === m.key)
  );
};

// const updateAvailableSectors = () => {
//   availableSectors.value = getSectors.value.filter(
//     (s) =>
//       s.key == scheduleToEdit.value.sector.key ||
//       !getSchedule.value.schedule.find((sch) => sch.sector.key === s.key)
//   );
// };

const init = async () => {
  restrictions.value = await store.dispatch("getAllRestrictions", {
    liftingDateFrom: props.initialDate,
    status: "ForLifting",
  });

  let allSchedules = [
    ...getSchedule.value.schedule
      .filter((schedule) => schedule.departure.key === props.departureKey)
      .map((ds) => ({ ...ds })),
  ];

  schedules.value = allSchedules.sort((a, b) => {
    if (a.sort === undefined && b.sort === undefined) {
      return 0;
    } else if (a.sort === undefined) {
      return 1;
    } else if (b.sort === undefined) {
      return -1;
    } else {
      return a.sort - b.sort;
    }
  });
};

init();

const filterOptions = () => {
  filteredSectors.value = getSectors.value.filter((sector) =>
    sector.name.toLowerCase().includes(searchTerm.value.toLowerCase())
  );
};

const selectSector = (sector) => {
  sectorKey.value = sector.key;
  searchTerm.value = sector.name;
  showOptions.value = false;
};

const handleBlur = () => {
  // Delay to allow selection before hiding options
  setTimeout(() => {
    showOptions.value = false;
  }, 100);
};

const onSelectColor = (colorSelected) => {
  scheduleToEdit.value.color =
    scheduleToEdit.value.color !== colorSelected ? colorSelected : "";
};

const onEdit = async (schedule) => {
  scheduleToEdit.value = {
    ...schedule,
    sectorKey: schedule.sector?.key,
  };
  sectorKey.value = schedule.sector?.key;
  searchTerm.value = schedule.sector?.name;
  selectedMembers.value = [schedule.user, ...(schedule.users || [])].filter(
    (u) => !!u
  );

  updateAvailableMembers();
};

const onEditSchedule = async () => {
  try {
    loadingEdit.value = true;

    await store.dispatch("updateSchedule", {
      schedule: {
        key: scheduleToEdit.value.key,
        name: scheduleToEdit.value.name,
        isToDispatch: scheduleToEdit.value.isToDispatch,
        sectorKey: sectorKey.value,
        metered: scheduleToEdit.value.metered,
        unit: scheduleToEdit.value.unit,
        color: scheduleToEdit.value.color,
      },
    });

    scheduleToEdit.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingEdit.value = false;
  }
};

const onMoveSchedule = async (event) => {
  try {
    loadingEdit.value = true;

    if (event.removed) {
      const departureSchedules = getSchedule.value.schedule
        .filter(
          (schedule) =>
            schedule.departure.key === event.removed.element.departure.key &&
            schedule.key !== event.removed.element.key
        )
        .sort((a, b) => {
          const aPriority = a.sort || 0;
          const bPriority = b.sort || 0;

          return aPriority - bPriority;
        });

      const promises = [];

      for (let i = event.removed.oldIndex; i < departureSchedules.length; i++) {
        promises.push(
          store.dispatch("updateSchedule", {
            schedule: {
              key: departureSchedules[i].key,
              departureKey: props.departureKey,
              sort: i + 1,
            },
          })
        );
      }

      await Promise.all(promises);
    }

    if (event.moved) {
      const departureSchedules = getSchedule.value.schedule
        .filter(
          (schedule) =>
            schedule.departure.key === event.moved.element.departure.key
        )
        .sort((a, b) => {
          const aPriority = a.sort || 0;
          const bPriority = b.sort || 0;

          return aPriority - bPriority;
        });

      const promises = [];

      if (event.moved.oldIndex < event.moved.newIndex) {
        for (let i = event.moved.oldIndex + 1; i <= event.moved.newIndex; i++) {
          promises.push(
            store.dispatch("updateSchedule", {
              schedule: {
                key: departureSchedules[i].key,
                departureKey: props.departureKey,
                sort: i,
              },
            })
          );
        }

        promises.push(
          store.dispatch("updateSchedule", {
            schedule: {
              key: departureSchedules[event.moved.oldIndex].key,
              departureKey: props.departureKey,
              sort: event.moved.newIndex + 1,
            },
          })
        );
      }

      if (event.moved.oldIndex > event.moved.newIndex) {
        for (let i = event.moved.newIndex; i < event.moved.oldIndex; i++) {
          promises.push(
            store.dispatch("updateSchedule", {
              schedule: {
                key: departureSchedules[i].key,
                departureKey: props.departureKey,
                sort: i + 2,
              },
            })
          );
        }

        promises.push(
          store.dispatch("updateSchedule", {
            schedule: {
              key: departureSchedules[event.moved.oldIndex].key,
              departureKey: props.departureKey,
              sort: event.moved.newIndex + 1,
            },
          })
        );
      }

      await Promise.all(promises);
    }

    if (event.added) {
      const departureSchedules = getSchedule.value.schedule
        .filter((schedule) => schedule.departure.key === props.departureKey)
        .sort((a, b) => {
          const aPriority = a.sort || 0;
          const bPriority = b.sort || 0;

          return aPriority - bPriority;
        });

      let sort = event.added.newIndex + 1;

      await store.dispatch("updateSchedule", {
        schedule: {
          key: event.added.element.key,
          departureKey: props.departureKey,
          sort: sort,
        },
      });

      let isToSync = event.added.newIndex !== departureSchedules.length;
      if (isToSync) {
        const promises = [];

        for (let i = event.added.newIndex; i < departureSchedules.length; i++) {
          promises.push(
            store.dispatch("updateSchedule", {
              schedule: {
                key: departureSchedules[i].key,
                departureKey: props.departureKey,
                sort: i + 2,
              },
            })
          );
        }

        await Promise.all(promises);
      }
    }

    scheduleToEdit.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingEdit.value = false;
  }
};

const onCompleteCreateSchedule = async (schedule) => {
  allowCreate.value = false;

  if (!schedule || !schedule.key) return;

  if (schedule.isToDispatch) {
    const promises = [];
    const dateSchedules = getSchedule.value.schedule
      .filter((schedule) => schedule.departureKey === props.departureKey)
      .sort((a, b) => {
        const aPriority = a.sort || 0;
        const bPriority = b.sort || 0;

        return aPriority - bPriority;
      })
      .filter((s) => s.key !== schedule.key);

    for (let i = 0; i < dateSchedules.length; i++) {
      promises.push(
        store.dispatch("updateSchedule", {
          schedule: {
            key: dateSchedules[i].key,
            departureKey: props.departureKey,
            sort: i + 2,
          },
        })
      );
    }

    await Promise.all(promises);

    schedules.value.unshift(schedule);
  } else {
    schedules.value.push(schedule);
  }
};

const onClone = async (schedule) => {
  try {
    loadingClone.value = true;

    const nextColumnDeparture = getWeekDepartures.value[props.ind + 1];

    const response = await store.dispatch("createSchedule", {
      schedule: {
        type: "Weekly",
        name: schedule.name,
        weekKey: schedule.weekKey,
        date: props.initialDate,
        color: schedule.color,
        status: schedule.status,
        timeout: schedule.timeout,
        isToDispatch: schedule.isToDispatch,
        userKey: nextColumnDeparture.user?.key, // TODO
        usersKey: nextColumnDeparture.users?.map((u) => u.key),
        sectorKey: schedule.sector.key,
        unit: schedule.unit,
        metered: schedule.metered,
        departureKey: nextColumnDeparture.departureKey,
        sort: 0,
      },
      isClone: true,
    });

    const departureSchedules = getSchedule.value.schedule.filter(
      (schedule) => schedule.departure.key === response.departure.key
    );

    await store.dispatch("updateSchedule", {
      schedule: {
        key: response.key,
        departureKey: response.departure.key,
        sort: departureSchedules.length,
      },
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingClone.value = false;
  }
};

const onDelete = async (schedule) => {
  scheduleToDelete.value = { ...schedule };
  modalDeleteSchedule.value = true;
};

const onMarkSchedule = async (schedule) => {
  try {
    loadingMark.value = true;

    await store.dispatch("createMarkedSchedule", {
      scheduleKey: schedule.key,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingMark.value = false;
  }
};

const onDeleteSchedule = async () => {
  try {
    loadingDelete.value = true;

    await store.dispatch("deleteSchedule", {
      key: scheduleToDelete.value.key,
    });

    modalDeleteSchedule.value = false;
    scheduleToDelete.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingDelete.value = false;
  }
};

const onUpdateWeekDepartureUser = async () => {
  weekDepartureSelected.value = {
    ...getWeekDepartures.value[props.ind],
    name: getDeparturesMap.value[props.departureKey].name,
  };
  modalUpdateWeekDepartureUser.value = true;
  openOptions.value = false;
};

const onUpdateWeekDeparture = async () => {
  weekDepartureSelected.value = {
    ...getWeekDepartures.value[props.ind],
    name: getDeparturesMap.value[props.departureKey].name,
  };
  modalUpdateWeekDeparture.value = true;
  openOptions.value = false;
};

const onDeleteWeekDeparture = async () => {
  weekDepartureSelected.value = {
    ...getWeekDepartures.value[props.ind],
    name: getDeparturesMap.value[props.departureKey].name,
  };
  modalDeleteWeekDeparture.value = true;
  openOptions.value = false;
};
</script>

<template>
  <div class="flex mb-4">
    <div class="flex justify-center w-full">
      <p class="cursor-pointer">
        {{ getDeparturesMap[props.departureKey].name }}
      </p>
      <button
        v-if="!allowCreate"
        class="
          text-primary
          border
          text-md
          font-medium
          border-primary
          px-1
          ml-2
          rounded-full
          w-6
          h-6
        "
        @click="allowCreate = true"
      >
        +
      </button>
    </div>
    <div class="relative">
      <!-- <button @click="openOptions = !openOptions" class="flex flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"> -->
      <a class="flex cursor-pointer" @click="openOptions = !openOptions">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :class="{ 'rotate-180': openOptions, 'rotate-0': !openOptions }"
          class="
            inline
            transition-transform
            duration-200
            transform
            text-primary
          "
        >
          <circle
            opacity="0.1"
            cx="12"
            cy="12"
            r="12"
            transform="rotate(-90 12 12)"
            fill="black"
          />
          <path
            d="M16.9779 10.6277C16.9782 10.7398 16.954 10.8505 16.9069 10.9517C16.8598 11.053 16.791 11.1422 16.7057 11.2127L12.2917 14.8352C12.1601 14.9456 11.995 15.0059 11.8246 15.0059C11.6542 15.0059 11.4891 14.9456 11.3574 14.8352L6.94349 11.0852C6.79326 10.9579 6.69878 10.775 6.68085 10.5767C6.66291 10.3784 6.72299 10.1809 6.84786 10.0277C6.97273 9.87458 7.15216 9.77826 7.34669 9.75998C7.54122 9.74169 7.7349 9.80294 7.88513 9.93024L11.8283 13.2827L15.7714 10.0427C15.8794 9.95103 16.0109 9.89277 16.1503 9.87486C16.2897 9.85695 16.4313 9.88014 16.5582 9.94168C16.6851 10.0032 16.7921 10.1005 16.8665 10.2221C16.9409 10.3437 16.9795 10.4845 16.9779 10.6277Z"
            fill="black"
          />
        </svg>
      </a>
      <!-- </button> -->
      <div
        v-if="openOptions"
        x-transition:enter="transition ease-out duration-100"
        x-transition:enter-start="transform opacity-0 scale-95"
        x-transition:enter-end="transform opacity-100 scale-100"
        x-transition:leave="transition ease-in duration-75"
        x-transition:leave-start="transform opacity-100 scale-100"
        x-transition:leave-end="transform opacity-0 scale-95"
        class="
          z-50
          absolute
          right-0
          w-full
          mt-2
          origin-top-right
          rounded-md
          shadow-lg
          md:w-32
          text-gray
        "
      >
        <div class="p-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
          <a
            class="
              block
              p-2
              mt-2
              text-sm
              bg-transparent
              rounded-lg
              dark-mode:bg-transparent
              dark-mode:hover:bg-gray-600
              dark-mode:focus:bg-gray-600
              dark-mode:focus:text-white
              dark-mode:hover:text-white
              dark-mode:text-gray-200
              md:mt-0
              hover:text-gray-900
              focus:text-gray-900
              hover:bg-gray-200
              focus:bg-gray-200 focus:outline-none focus:shadow-outline
              cursor-pointer
            "
            @click="onUpdateWeekDepartureUser"
          >
            Responsable
          </a>
          <a
            class="
              block
              p-2
              mt-2
              text-sm
              bg-transparent
              rounded-lg
              dark-mode:bg-transparent
              dark-mode:hover:bg-gray-600
              dark-mode:focus:bg-gray-600
              dark-mode:focus:text-white
              dark-mode:hover:text-white
              dark-mode:text-gray-200
              md:mt-0
              hover:text-gray-900
              focus:text-gray-900
              hover:bg-gray-200
              focus:bg-gray-200 focus:outline-none focus:shadow-outline
              cursor-pointer
            "
            @click="onUpdateWeekDeparture"
          >
            Editar
          </a>
          <a
            class="
              block
              p-2
              mt-2
              text-sm
              bg-transparent
              rounded-lg
              dark-mode:bg-transparent
              dark-mode:hover:bg-red-600
              dark-mode:focus:bg-red-600
              dark-mode:focus:text-red
              dark-mode:hover:text-red
              dark-mode:text-red-200
              md:mt-0
              hover:text-red-900
              focus:text-red-900
              hover:bg-red-200
              focus:bg-red-200 focus:outline-none focus:shadow-outline
              cursor-pointer
            "
            @click="onDeleteWeekDeparture"
            >Eliminar</a
          >
        </div>
      </div>
    </div>
    <!--
    <button
      class="hover:bg-red-50 px-2"
      @click="onOpenModalDeleteWeekDeparture(props.departureKey)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56976C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56976V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z"
          fill="#E00F57"
        />
      </svg>
    </button>-->
  </div>

  <draggable
    :list="schedules || []"
    class="list-group"
    :id="props.date"
    group="schedules"
    tag="ul"
    handle=".handle"
    ghost-class="ghost"
    item-key="key"
    @change="onMoveSchedule"
  >
    <template #item="{ element: schedule }">
      <div :class="schedule.isRestriction ? '' : 'handle'">
        <Form
          v-if="scheduleToEdit && scheduleToEdit.key === schedule.key"
          :validation-schema="{
            sectorKey: 'required',
          }"
          @submit="onEditSchedule"
        >
          <div class="mb-3 relative">
            <Field v-model="sectorKey" name="sectorKey" v-slot="{ field }">
              <input type="hidden" v-bind="field" />
            </Field>
            <input
              type="text"
              v-model="searchTerm"
              @input="filterOptions"
              @focus="showOptions = true"
              @blur="handleBlur"
              placeholder="Seleccione sector"
              class="
                relative
                py-3
                px-2
                w-full
                bg-white
                rounded-sm
                border
                outline-none
                text-gray-700
                placeholder-gray-400
              "
            />
            <ul
              v-if="showOptions && filteredSectors.length"
              class="
                absolute
                z-10
                bg-white
                border
                rounded-sm
                w-full
                mt-1
                max-h-60
                overflow-auto
              "
            >
              <li
                v-for="(sector, i) in filteredSectors"
                :key="i"
                @mousedown="selectSector(sector)"
                class="px-2 py-1 cursor-pointer hover:bg-gray-200"
              >
                {{ sector.name }}
              </li>
            </ul>
            <div>
              <ErrorMessage name="sectorKey" v-slot="{ message }">
                <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
              </ErrorMessage>
            </div>
          </div>

          <textarea
            v-model="scheduleToEdit.name"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
          ></textarea>

          <div class="mb-3">
            <template v-for="c in colors" :key="c">
              <button
                :class="
                  c === scheduleToEdit.color ? 'ring-2 ring-primary-500/50' : ''
                "
                class="... rounded-full h-4 w-4 mr-1"
                :style="{ 'background-color': c }"
                type="text"
                @click.prevent.stop="onSelectColor(c)"
              ></button>
            </template>
          </div>

          <div class="flex mb-2 justify-between">
            <button
              type="submit"
              class="
                rounded
                bg-primary
                py-2
                px-4
                mr-2
                text-white text-sm
                font-medium
                flex
              "
              :disabled="loadingEdit"
            >
              <LoadingButtonIcon v-if="loadingEdit" />
              Guardar
            </button>
            <button
              type="button"
              class="mx-auto cursor-pointer p-1 rounded px-2"
              :style="{
                'background-color': scheduleToEdit.isToDispatch
                  ? '#ffeb00 !important'
                  : 'transparent',
                border: '1px solid #3565AE',
              }"
              @click.stop="
                scheduleToEdit.isToDispatch = !scheduleToEdit.isToDispatch
              "
            >
              <img src="@/assets/icons/truck.svg" :style="{ height: '12px' }" />
            </button>
            <button
              @click="scheduleToEdit = null"
              class="py-2 text-gray text-xl font-bold"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <path
                  d="M13.4577 4.54025L12.4589 3.5415L8.49935 7.50109L4.53977 3.5415L3.54102 4.54025L7.5006 8.49984L3.54102 12.4594L4.53977 13.4582L8.49935 9.49859L12.4589 13.4582L13.4577 12.4594L9.4981 8.49984L13.4577 4.54025Z"
                  fill="#717191"
                />
              </svg>
            </button>
          </div>
        </Form>
        <div
          v-else
          :id="schedule.id"
          class="border p-1 mb-4 text-sm rounded p-3 relative cursor-pointer"
          :class="
            schedule.status === 'Registered'
              ? 'bg-white'
              : schedule.status === 'Completed'
              ? 'bg-purple text-white'
              : 'bg-gray-100 hover:border-primary hover:bg-sky'
          "
          :style="
            schedule.status !== 'Completed'
              ? {
                  'background-color': schedule.isToDispatch
                    ? '#fffde5'
                    : schedule.color || '',
                }
              : null
          "
        >
          <div style="min-height: 66px">
            <input
              v-if="!schedule.isRestriction"
              type="checkbox"
              class="
                checked:bg-primary checked:border-transparent
                cursor-pointer
                mr-2
                ...
              "
              :disabled="
                schedule.status === 'Completed' || schedule.isToDispatch
              "
              :checked="props.scheduleSelected.includes(schedule.key)"
              @click.stop="emit('onSelectSchedule', schedule)"
            />
            <div v-if="schedule.sector">{{ schedule.sector.name }}</div>
            <label>{{ schedule.name }}</label>
            <div v-if="schedule.metered">
              {{ schedule.metered }}{{ schedule.unit }}
            </div>
            <div
              v-if="schedule.user || schedule.users"
              class="flex flex-wrap gap-2 mt-2"
            >
              <Avatar v-if="schedule.user" :member="schedule.user" />
              <span v-for="user in schedule.users || []" :key="user.key">
                <Avatar :member="user" />
              </span>
            </div>
            <div v-if="schedule.isToDispatch" class="text-right">
              <button class="mx-auto p-1 absolute top-0 right-5">
                <IconDispatch
                  :color="
                    schedule.status === 'Completed' ? '#FFFFFF' : undefined
                  "
                />
              </button>
            </div>
          </div>

          <button
            v-if="schedule.status !== 'Completed' && !schedule.isRestriction"
            class="absolute top-0 right-0 p-1 hover:bg-white hover:text-primary"
            @click="onEdit(schedule)"
          >
            <svg viewBox="0 0 512 512" class="h-3 w-3">
              <path
                fill="currentColor"
                d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
                class=""
              ></path>
            </svg>
          </button>
          <button
            v-if="schedule.status !== 'Completed' && !schedule.isRestriction"
            class="
              absolute
              top-12
              right-0
              p-1
              hover:bg-white hover:text-red-500
            "
            @click="onDelete(schedule)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                d="M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56976C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56976V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            v-if="
              schedule.status !== 'Completed' &&
              getWeekDepartures.length - 1 !== ind &&
              !schedule.isRestriction
            "
            class="absolute top-6 right-0 p-1 hover:bg-white hover:text-primary"
            @click="onClone(schedule)"
          >
            <svg viewBox="0 0 24 24" class="h-3 w-3" fill="none">
              <path
                d="M14 16.75H6C5.27065 16.75 4.57118 16.4603 4.05546 15.9445C3.53973 15.4288 3.25 14.7293 3.25 14V6C3.25 5.27065 3.53973 4.57118 4.05546 4.05546C4.57118 3.53973 5.27065 3.25 6 3.25H14C14.7293 3.25 15.4288 3.53973 15.9445 4.05546C16.4603 4.57118 16.75 5.27065 16.75 6V14C16.75 14.7293 16.4603 15.4288 15.9445 15.9445C15.4288 16.4603 14.7293 16.75 14 16.75ZM6 4.75C5.66848 4.75 5.35054 4.8817 5.11612 5.11612C4.8817 5.35054 4.75 5.66848 4.75 6V14C4.75 14.3315 4.8817 14.6495 5.11612 14.8839C5.35054 15.1183 5.66848 15.25 6 15.25H14C14.3315 15.25 14.6495 15.1183 14.8839 14.8839C15.1183 14.6495 15.25 14.3315 15.25 14V6C15.25 5.66848 15.1183 5.35054 14.8839 5.11612C14.6495 4.8817 14.3315 4.75 14 4.75H6Z"
                fill="currentColor"
              />
              <path
                d="M18 20.75H10C9.27065 20.75 8.57118 20.4603 8.05546 19.9445C7.53973 19.4288 7.25 18.7293 7.25 18V16H8.75V18C8.75 18.3315 8.8817 18.6495 9.11612 18.8839C9.35054 19.1183 9.66848 19.25 10 19.25H18C18.3315 19.25 18.6495 19.1183 18.8839 18.8839C19.1183 18.6495 19.25 18.3315 19.25 18V10C19.25 9.66848 19.1183 9.35054 18.8839 9.11612C18.6495 8.8817 18.3315 8.75 18 8.75H16V7.25H18C18.7293 7.25 19.4288 7.53973 19.9445 8.05546C20.4603 8.57118 20.75 9.27065 20.75 10V18C20.75 18.7293 20.4603 19.4288 19.9445 19.9445C19.4288 20.4603 18.7293 20.75 18 20.75Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            v-if="!schedule.isRestriction"
            class="
              absolute
              bottom-0
              right-0
              p-1
              hover:bg-white hover:text-primary
            "
            @click="onMarkSchedule(schedule)"
          >
            <svg viewBox="0 0 512 512" class="h-3 w-3">
              <path
                fill="currentColor"
                d="M173.898 439.404l-166.4-166.4 60.796-60.796L192 312.204l318.405-318.405 60.796 60.796-379.303 379.303z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </template>
  </draggable>

  <div
    v-for="(restriction, i) in restrictions"
    :key="i"
    class="
      border
      p-1
      mb-4
      text-sm
      rounded
      p-3
      relative
      cursor-pointer
      bg-red-100
      border-red
    "
  >
    <p class="text-red-500 font-medium w-100">Restrición</p>
    <label>{{ restriction.name }}</label>
  </div>

  <ScheduleForm
    v-if="allowCreate"
    :initial-date="props.initialDate"
    :week-departure-key="props.weekDepartureKey"
    :departure-key="props.departureKey"
    :week-key="props.weekKey"
    :is-current-week="props.isCurrentWeek"
    @onComplete="onCompleteCreateSchedule"
  />

  <Modal
    :open="modalDeleteSchedule"
    @update:open="modalDeleteSchedule = $event"
  >
    <template #body>
      <span v-if="scheduleToDelete" class="font-medium">
        ¿Desea eliminar la actividad {{ scheduleToDelete.departure.name }} > "{{
          scheduleToDelete.sector.name
        }}"?
      </span>
      <br />
    </template>
    <template #buttonDelete>
      <button
        type="button"
        class="
          w-full
          inline-flex
          justify-center
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          bg-red-600
          text-base
          font-medium
          text-white
          hover:bg-red-700
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
          sm:ml-3 sm:w-auto sm:text-sm
        "
        :disabled="loadingDelete"
        @click="onDeleteSchedule"
      >
        <LoadingButtonIcon v-if="loadingDelete" />
        Eliminar
      </button>
    </template>
  </Modal>

  <UpdateWeekDepartureUserModal
    v-if="modalUpdateWeekDepartureUser"
    :open="modalUpdateWeekDepartureUser"
    :week-departure="weekDepartureSelected"
    @update:open="modalUpdateWeekDepartureUser = $event"
  />
  <UpdateWeekDepartureModal
    v-if="modalUpdateWeekDeparture"
    :open="modalUpdateWeekDeparture"
    :week-departure="weekDepartureSelected"
    @update:open="modalUpdateWeekDeparture = $event"
  />
  <DeleteWeekDepartureModal
    v-if="modalDeleteWeekDeparture"
    :open="modalDeleteWeekDeparture"
    :week-departure="weekDepartureSelected"
    @update:open="modalDeleteWeekDeparture = $event"
  />
  <!--
  <div
    v-for="restriction in restrictions"
    :key="restriction.key"
    class="border p-1 mt-4 text-sm rounded p-3 relative cursor-pointer"
    :class="'bg-red-100 border-red'"
  >
    <div style="min-height: 60px">
      <label class="text-red-500 font-medium">Restrición</label>
      <p>{{ restriction.name }}</p>
    </div>
  </div>
  -->
</template>

<style>
.element-card {
  position: relative;
  background-color: white;
  height: auto;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  min-height: 30px;
  margin-bottom: 10px;
  word-break: break-all;
  text-align: left;
}
</style>
