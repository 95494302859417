<template>
  <div v-if="loadingWeekDays" class="flex mt-8 min-h-screen pt-6">
    <div v-for="(day, index) in weekDays" :key="index" class="border-r flex-1">
      <div :id="day.date" class="px-2">
        <DayList
          :id="day.id"
          :date="day.date"
          :week-key="props.weekKey"
          :is-current-week="days[0].date === getWeekInitialDate"
          :schedule-selected="props.scheduleSelected"
          @onSelectSchedule="onSelectSchedule"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import DayList from "./DayList";

const emit = defineEmits("onSelectSchedule");

const props = defineProps({
  weekKey: {
    type: String,
    required: true,
  },
  initialDate: {
    type: String,
    required: true,
  },
  scheduleSelected: {
    type: Array,
  },
});

const store = useStore();

const loadingWeekDays = ref(false);
const weekDays = ref([
  {
    id: 0,
    name: "Lunes",
  },
  {
    id: 1,
    name: "Martes",
  },
  {
    id: 2,
    name: "Miércoles",
  },
  {
    id: 3,
    name: "Jueves",
  },
  {
    id: 4,
    name: "Viernes",
  },
  {
    id: 5,
    name: "Sábado",
  },
]);

const days = computed(() => [
  {
    id: 0,
    name: "Lunes",
  },
  {
    id: 1,
    name: "Martes",
  },
  {
    id: 2,
    name: "Miércoles",
  },
  {
    id: 3,
    name: "Jueves",
  },
  {
    id: 4,
    name: "Viernes",
  },
  {
    id: 5,
    name: "Sábado",
  },
]);
const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);

const getDay = (date, incrementDays) => {
  return dayjs(new Date(date)).add(incrementDays, "d").format("YYYY-MM-DD");
};

const loadWeekDays = (date) => {
  loadingWeekDays.value = false;

  weekDays.value = weekDays.value.map((wd) => ({
    ...wd,
    date: getDay(date, wd.id + 1),
  }));

  loadingWeekDays.value = true;
};

watch(
  () => props.initialDate,
  (value) => loadWeekDays(value)
);

const init = () => {
  loadWeekDays(props.initialDate);
};

init();

const onSelectSchedule = (schedule) => {
  emit("onSelectSchedule", schedule);
};
</script>

<style>
.ghost {
  opacity: 0.5;
}
</style>
