<template>
  <div v-if="getSchedule && getTypesNonCompliance">
    <div v-show="!modeDraft" class="flex justify-between">
      <div class="flex w-full">
        <button
          class="
            text-primary
            border
            text-sm
            font-medium
            border-primary
            p-2
            px-4
          "
          @click="day = getWeekInitialDate"
        >
          Semana Actual
        </button>

        <input
          v-model="day"
          type="date"
          step="7"
          min="1970-01-05"
          class="
            mx-5
            relative
            p-2
            bg-white
            rounded
            border
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none
            px-4
          "
        />
        <h2
          class="my-auto px-2 font-medium text-primary"
          :style="{ 'font-size': '24px' }"
        >
          PPC: {{ getSchedule.ppc }}%
        </h2>
      </div>
      <div class="flex justify-end w-full">
        <button
          class="
            text-primary
            border
            text-sm
            font-medium
            border-primary
            p-2
            px-3
          "
          @click="onOpenDrawerCauses(true)"
        >
          Causas de no cumplimiento
        </button>

        <button
          class="
            text-primary
            border
            text-sm
            font-medium
            border-primary
            p-2
            mx-2
            px-3
            h-full
          "
          @click="onCalculatePPC"
        >
          Calcular PPC
        </button>

        <button
          class="
            text-white
            border
            text-sm
            font-medium
            bg-primary
            border-primary
            pb-2
            pt-3
            mx-2
            px-3
            flex
          "
          @click="onOpenDrawerUploadSchedules(true)"
        >
          Importar
        </button>

        <button
          class="
            text-white
            border
            text-sm
            font-medium
            bg-greenDark
            border-greenDark
            pb-2
            pt-3
            mx-2
            px-3
            flex
          "
          @click="onExportWeeklySchedulesReport"
        >
          <LoadingButtonIcon v-if="loadingExport" /> Exportar
        </button>
      </div>
    </div>

    <DayBoard
      v-if="!modeDraft"
      :day="day"
      :week-key="getSchedule.key"
      :initial-date="initialDate"
      :schedule-selected="scheduleSelected"
      @onSelectSchedule="onSelectSchedule"
      @onActiveModeDraft="modeDraft = true"
    />

    <DraftBoard
      v-if="modeDraft"
      :day="day"
      :week-key="getSchedule.key"
      :initial-date="initialDate"
      @onActiveModeDraft="modeDraft = false"
    />

    <EndDrawer :open="drawerCauses" @update:open="drawerCauses = $event">
      <CausesForm @onComplete="onOpenDrawerCauses(false)" />
    </EndDrawer>

    <EndDrawer
      :open="drawerUploadSchedules"
      @update:open="drawerUploadSchedules = $event"
    >
      <UploadSchedules
        :date="initialDate"
        @onComplete="onOpenDrawerUploadSchedules(false)"
      />
    </EndDrawer>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import CausesForm from "./components/CausesForm";
import DayBoard from "./components/DayBoard";
import UploadSchedules from "./components/UploadSchedules";
import DraftBoard from "./components/DraftBoard.vue";

const store = useStore();

const modeDraft = ref(false);
const day = ref("");
const dayColumns = ref([]);
const drawerCauses = ref(false);
const scheduleSelected = ref([]);
const initialDate = ref("");
const loadingExport = ref(false);
const drawerUploadSchedules = ref(false);

const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);
const getTypesNonCompliance = computed(
  () => store.getters.getTypesNonCompliance
);
const getSchedule = computed(() => store.getters.getSchedule);
const getMembers = computed(() => store.getters.getMembers);
const getSectors = computed(() => store.getters.getSectors);

watch(
  () => getSchedule.value,
  (schedule) => {
    if (schedule) {
      let columns = [];

      for (let i = 0; i < 6; i++) {
        columns.push({ id: i, day: getDay(i + 1), schedules: [] });
      }

      if (schedule.schedule?.length) {
        let indColumn;

        for (let auxSchedule of schedule.schedule) {
          indColumn = columns.findIndex((c) => c.day === auxSchedule.date);
          if (indColumn >= 0) {
            if (auxSchedule.isToDispatch) {
              columns[indColumn].schedules.unshift({ ...auxSchedule });
            } else {
              columns[indColumn].schedules.push({ ...auxSchedule });
            }
          }
        }
      }

      dayColumns.value = columns;
    }
  },
  { deep: true }
);

watch(
  () => day.value,
  (currentDay, prevDay) => {
    if (currentDay !== prevDay && currentDay) {
      dayColumns.value = [];
      loadScheduleByWeek(currentDay);
    }
  }
);

const loadScheduleByWeek = async (date) => {
  initialDate.value = date;
  await store.dispatch("findScheduleByWeek", { weekDate: date });
};

const getDay = (incrementDays) => {
  return dayjs(new Date(day.value))
    .add(incrementDays, "d")
    .format("YYYY-MM-DD");
};

const init = async () => {
  day.value = getWeekInitialDate.value;

  loadScheduleByWeek(day.value);

  await store.dispatch("getAllSubcontractors");

  await store.dispatch("getAllDepartures", { includeSubdepartures: true });

  if (!getSectors.value) await store.dispatch("getAllSectors");

  if (!getTypesNonCompliance.value)
    await store.dispatch("getAllTypes", { type: "NonCompliance" });

  if (!getMembers.value)
    await store.dispatch("getAllMembers", { type: ["Worker", "Admin"] });
};

init();

const onSelectSchedule = async (schedule) => {
  if (scheduleSelected.value.includes(schedule.key)) {
    const ind = scheduleSelected.value.findIndex((sc) => sc === schedule.key);
    if (ind >= 0) scheduleSelected.value.splice(ind, 1);
  } else {
    scheduleSelected.value.push(schedule.key);
  }
};

const onCalculatePPC = async () => {
  if (!scheduleSelected.value?.length) return;

  await store.dispatch("calculatePPCByDepartures", {
    status: "Completed",
    scheduleKey: scheduleSelected.value,
    weekKey: getSchedule.value.key,
  });

  scheduleSelected.value = [];
};

const onExportWeeklySchedulesReport = async () => {
  try {
    loadingExport.value = true;

    const dateTo = getDay(6);

    const reportUrl = await store.dispatch(
      "generatePlanningWeeklySchedulesReport",
      {
        dateFrom: day.value,
        dateTo: dateTo,
      }
    );

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `planificacion-semanal.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(error);
  } finally {
    loadingExport.value = false;
  }
};

const onOpenDrawerCauses = (open = true) => {
  drawerCauses.value = open;
};

const onOpenDrawerUploadSchedules = (open = true) => {
  drawerUploadSchedules.value = open;

  if (!open) {
    day.value = initialDate.value;

    loadScheduleByWeek(day.value);
  }
};
</script>
