<script setup>
import { defineEmits, defineProps, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import draggable from "vuedraggable";
import ScheduleForm from "./ScheduleForm";
import IconDispatch from "@/components/icons/Dispatch";

const emit = defineEmits(["onSelectSchedule"]);

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  date: {
    type: String,
  },
  weekKey: {
    type: String,
    required: true,
  },
  isCurrentWeek: {
    type: Boolean,
    required: true,
  },
  scheduleSelected: {
    type: Array,
  },
});

const store = useStore();

const schedules = ref([]);
const restrictions = ref([]);
const allowCreate = ref(false);
const daysNames = ref([
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
]);

const colors = ref([
  "#B6D7A8",
  "#A2C4C9",
  "#D5A6BD",
  "#F6B26A",
  "#BDBDBD",
  "#0AFF00",
  "#00FFFF",
  "#4A86E8",
  "#FF01FF",
  "#9900FF",
]);
const scheduleToEdit = ref(null);
const loadingEdit = ref(false);
const loadingClone = ref(false);

const getSchedule = computed(() => store.getters.getSchedule);
const getSchedulesCloned = computed(() => store.getters.getSchedulesCloned);
const getMembers = computed(() => store.getters.getMembers);

watch(
  () => getSchedulesCloned.value,
  (currentValue) => {
    let last = currentValue[currentValue.length - 1];
    if (last.date === props.date) onCompleteCreateSchedule(last);
  }
);

watch(
  () => getSchedule.value,
  (currentValue) => {
    if (!currentValue) return;

    let currentSchedule = currentValue.schedule;
    let schedulesFound = currentSchedule.filter((sch) =>
      schedules.value.find((s) => s.key === sch.key)
    );
    schedulesFound.forEach((schedule) => {
      let scheduleInd = schedules.value.findIndex(
        (sch) => sch.key === schedule.key
      );
      if (scheduleInd >= 0) schedules.value[scheduleInd] = { ...schedule };
    });
  },
  { deep: true }
);

const init = async () => {
  restrictions.value = await store.dispatch("getAllRestrictions", {
    liftingDateFrom: props.date,
    status: "ForLifting",
  });

  let allSchedules = [
    ...getSchedule.value.schedule
      .filter((schedule) => schedule.date === props.date)
      .map((ds) => ({ ...ds })),
  ];

  schedules.value = allSchedules.sort((a, b) => {
    if (a.sort === undefined && b.sort === undefined) {
      return 0;
    } else if (a.sort === undefined) {
      return 1;
    } else if (b.sort === undefined) {
      return -1;
    } else {
      return a.sort - b.sort;
    }
  });
};

init();

const onSelectColor = (colorSelected) => {
  scheduleToEdit.value.color =
    scheduleToEdit.value.color !== colorSelected ? colorSelected : "";
};

const onEdit = async (schedule) => {
  scheduleToEdit.value = { ...schedule, userKey: schedule.user?.key };
};

const onEditSchedule = async () => {
  try {
    loadingEdit.value = true;

    await store.dispatch("updateSchedule", {
      schedule: {
        key: scheduleToEdit.value.key,
        name: scheduleToEdit.value.name,
        isToDispatch: scheduleToEdit.value.isToDispatch,
        userKey: scheduleToEdit.value.userKey,
        metered: scheduleToEdit.value.metered,
        unit: scheduleToEdit.value.unit,
        color: scheduleToEdit.value.color,
      },
    });

    scheduleToEdit.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingEdit.value = false;
  }
};

const onMoveSchedule = async (event) => {
  try {
    loadingEdit.value = true;

    if (event.removed) {
      const dateSchedules = getSchedule.value.schedule
        .filter(
          (schedule) =>
            schedule.date === event.removed.element.date &&
            schedule.key !== event.removed.element.key
        )
        .sort((a, b) => {
          const aPriority = a.sort || 0;
          const bPriority = b.sort || 0;

          return aPriority - bPriority;
        });

      const promises = [];

      for (let i = event.removed.oldIndex; i < dateSchedules.length; i++) {
        promises.push(
          store.dispatch("updateSchedule", {
            schedule: {
              key: dateSchedules[i].key,
              date: props.date,
              sort: i + 1,
            },
          })
        );
      }

      await Promise.all(promises);
    }

    if (event.moved) {
      const dateSchedules = getSchedule.value.schedule
        .filter((schedule) => schedule.date === event.moved.element.date)
        .sort((a, b) => {
          const aPriority = a.sort || 0;
          const bPriority = b.sort || 0;

          return aPriority - bPriority;
        });

      const promises = [];

      if (event.moved.oldIndex < event.moved.newIndex) {
        for (let i = event.moved.oldIndex + 1; i <= event.moved.newIndex; i++) {
          promises.push(
            store.dispatch("updateSchedule", {
              schedule: {
                key: dateSchedules[i].key,
                date: props.date,
                sort: i,
              },
            })
          );
        }

        promises.push(
          store.dispatch("updateSchedule", {
            schedule: {
              key: dateSchedules[event.moved.oldIndex].key,
              date: props.date,
              sort: event.moved.newIndex + 1,
            },
          })
        );
      }

      if (event.moved.oldIndex > event.moved.newIndex) {
        for (let i = event.moved.newIndex; i < event.moved.oldIndex; i++) {
          promises.push(
            store.dispatch("updateSchedule", {
              schedule: {
                key: dateSchedules[i].key,
                date: props.date,
                sort: i + 2,
              },
            })
          );
        }

        promises.push(
          store.dispatch("updateSchedule", {
            schedule: {
              key: dateSchedules[event.moved.oldIndex].key,
              date: props.date,
              sort: event.moved.newIndex + 1,
            },
          })
        );
      }

      await Promise.all(promises);
    }

    if (event.added) {
      const dateSchedules = getSchedule.value.schedule
        .filter((schedule) => schedule.date === props.date)
        .sort((a, b) => {
          const aPriority = a.sort || 0;
          const bPriority = b.sort || 0;

          return aPriority - bPriority;
        });

      let sort = event.added.newIndex + 1;

      await store.dispatch("updateSchedule", {
        schedule: {
          key: event.added.element.key,
          date: props.date,
          sort: sort,
        },
      });

      let isToSync = event.added.newIndex !== dateSchedules.length;
      if (isToSync) {
        const promises = [];

        for (let i = event.added.newIndex; i < dateSchedules.length; i++) {
          promises.push(
            store.dispatch("updateSchedule", {
              schedule: {
                key: dateSchedules[i].key,
                date: props.date,
                sort: i + 2,
              },
            })
          );
        }

        await Promise.all(promises);
      }
    }

    scheduleToEdit.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingEdit.value = false;
  }
};

const onCompleteCreateSchedule = async (schedule) => {
  allowCreate.value = false;

  if (!schedule || !schedule.key) return;

  if (schedule.isToDispatch) {
    const promises = [];
    const dateSchedules = getSchedule.value.schedule
      .filter((schedule) => schedule.date === props.date)
      .sort((a, b) => {
        const aPriority = a.sort || 0;
        const bPriority = b.sort || 0;

        return aPriority - bPriority;
      })
      .filter((s) => s.key !== schedule.key);

    for (let i = 0; i < dateSchedules.length; i++) {
      promises.push(
        store.dispatch("updateSchedule", {
          schedule: {
            key: dateSchedules[i].key,
            date: props.date,
            sort: i + 2,
          },
        })
      );
    }

    await Promise.all(promises);

    schedules.value.unshift(schedule);
  } else {
    schedules.value.push(schedule);
  }
};

const onClone = async (schedule) => {
  try {
    loadingClone.value = true;

    const response = await store.dispatch("createSchedule", {
      schedule: {
        type: "Weekly",
        name: schedule.name,
        weekKey: schedule.weekKey,
        date: schedule.date,
        color: schedule.color,
        status: schedule.status,
        timeout: schedule.timeout,
        isToDispatch: schedule.isToDispatch,
        userKey: schedule.user?.key,
        unit: schedule.unit,
        metered: schedule.metered,
        isClone: true,
        sort: 0,
      },
    });

    const dateSchedules = getSchedule.value.schedule.filter(
      (schedule) => schedule.date === response.date
    );

    await store.dispatch("updateSchedule", {
      schedule: {
        key: response.key,
        date: response.date,
        sort: dateSchedules.length,
      },
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingClone.value = false;
  }
};
</script>

<template>
  <div class="flex justify-center mb-4">
    <p>
      {{ daysNames[props.id] }}
    </p>
    <button
      v-if="!allowCreate"
      class="
        text-primary
        border
        text-md
        font-medium
        border-primary
        px-1
        ml-2
        rounded-full
        w-6
        h-6
      "
      @click="allowCreate = true"
    >
      +
    </button>
  </div>

  <draggable
    :list="schedules || []"
    class="list-group"
    :id="props.date"
    group="schedules"
    tag="ul"
    handle=".handle"
    ghost-class="ghost"
    item-key="key"
    @change="onMoveSchedule"
  >
    <template #item="{ element: schedule }">
      <div :class="schedule.isRestriction ? '' : 'handle'">
        <Form
          v-if="scheduleToEdit && scheduleToEdit.key === schedule.key"
          :validation-schema="{
            name: 'required',
            metered: 'decimal',
          }"
        >
          <textarea
            v-model="scheduleToEdit.name"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
          ></textarea>

          <div class="mb-3">
            <Field
              v-model="scheduleToEdit.metered"
              name="metered"
              v-slot="{ field }"
            >
              <input
                v-bind="field"
                type="text"
                class="
                  relative
                  p-2
                  mt-1
                  w-full
                  bg-white
                  rounded
                  border
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                  focus:outline-none focus:shadow-outline
                "
                placeholder="Ingrese metrado"
              />
            </Field>
            <div>
              <ErrorMessage name="metered" v-slot="{ message }">
                <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
              </ErrorMessage>
            </div>
          </div>

          <div class="mb-3">
            <Field v-model="scheduleToEdit.unit" name="unit" v-slot="{ field }">
              <select
                v-bind="field"
                class="
                  relative
                  py-3
                  px-2
                  w-full
                  bg-white
                  rounded-sm
                  border
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                "
                placeholder="Seleccione"
              >
                <option value="">Seleccione unidad</option>
                <option value="m">m</option>
                <option value="m2">m2</option>
                <option value="m3">m3</option>
                <option value="und">und</option>
                <option value="puntos">puntos</option>
                <option value="HH">HH</option>
                <option value="kg">kg</option>
              </select>
            </Field>
            <div>
              <ErrorMessage name="unit" v-slot="{ message }">
                <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
              </ErrorMessage>
            </div>
          </div>

          <div class="mb-3">
            <Field
              v-model="scheduleToEdit.userKey"
              name="userKey"
              v-slot="{ field }"
            >
              <select
                v-bind="field"
                class="
                  relative
                  py-2
                  px-2
                  w-full
                  bg-white
                  rounded-sm
                  border
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                "
                placeholder="Seleccione"
              >
                <option value="">Seleccione responsable</option>
                <option
                  v-for="member in getMembers"
                  :key="member.key"
                  :value="member.key"
                >
                  {{ member.lastName }} {{ member.lastName2 }} {{ member.name }}
                </option>
              </select>
            </Field>
            <div>
              <ErrorMessage name="userKey" v-slot="{ message }">
                <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
              </ErrorMessage>
            </div>
          </div>

          <div class="mb-3">
            <template v-for="c in colors" :key="c">
              <button
                :class="
                  c === scheduleToEdit.color ? 'ring-2 ring-primary-500/50' : ''
                "
                class="... rounded-full h-4 w-4 mr-1"
                :style="{ 'background-color': c }"
                type="text"
                @click.prevent.stop="onSelectColor(c)"
              ></button>
            </template>
          </div>

          <div class="flex mb-2 justify-between">
            <button
              class="
                rounded
                bg-primary
                py-2
                px-4
                mr-2
                text-white text-sm
                font-medium
                flex
              "
              :disabled="loadingEdit"
              @click="onEditSchedule"
            >
              <LoadingButtonIcon v-if="loadingEdit" />
              Guardar
            </button>
            <button
              class="mx-auto cursor-pointer p-1 rounded px-2"
              :style="{
                'background-color': scheduleToEdit.isToDispatch
                  ? '#ffeb00 !important'
                  : 'transparent',
                border: '1px solid #3565AE',
              }"
              @click.stop="
                scheduleToEdit.isToDispatch = !scheduleToEdit.isToDispatch
              "
            >
              <img src="@/assets/icons/truck.svg" :style="{ height: '12px' }" />
            </button>
            <button
              @click="scheduleToEdit = null"
              class="py-2 text-gray text-xl font-bold"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <path
                  d="M13.4577 4.54025L12.4589 3.5415L8.49935 7.50109L4.53977 3.5415L3.54102 4.54025L7.5006 8.49984L3.54102 12.4594L4.53977 13.4582L8.49935 9.49859L12.4589 13.4582L13.4577 12.4594L9.4981 8.49984L13.4577 4.54025Z"
                  fill="#717191"
                />
              </svg>
            </button>
          </div>
        </Form>
        <div
          v-else
          :id="schedule.id"
          class="border p-1 mb-4 text-sm rounded p-3 relative cursor-pointer"
          :class="
            schedule.status === 'Registered'
              ? 'bg-white'
              : schedule.status === 'Completed'
              ? 'bg-purple text-white'
              : 'bg-gray-100 hover:border-primary hover:bg-sky'
          "
          :style="{
            'background-color':
              schedule.isToDispatch && schedule.status !== 'Completed'
                ? '#fffde5'
                : schedule.color || '',
          }"
        >
          <div style="min-height: 60px">
            <input
              v-if="!schedule.isRestriction"
              type="checkbox"
              class="
                checked:bg-primary checked:border-transparent
                cursor-pointer
                mr-2
                ...
              "
              :disabled="schedule.status === 'Completed'"
              :checked="props.scheduleSelected.includes(schedule.key)"
              @click.stop="emit('onSelectSchedule', schedule)"
            />
            <label>{{ schedule.name }}</label>
            <div>{{ schedule.metered }}{{ schedule.unit }}</div>
            <div v-if="schedule.user">
              {{ schedule.user.lastName }} {{ schedule.user.lastName2 }}
              {{ schedule.user.name }}
            </div>
            <div v-if="schedule.isToDispatch" class="text-right">
              <button class="mx-auto p-1 absolute bottom-0 right-0">
                <!-- <img src="@/assets/icons/truck.svg" :style="{ height: '12px' }" color="#FFF" /> -->
                <IconDispatch
                  :color="
                    schedule.status === 'Completed' ? '#FFFFFF' : undefined
                  "
                />
              </button>
            </div>
          </div>
          <button
            v-if="schedule.status !== 'Completed' && !schedule.isRestriction"
            class="absolute top-0 right-0 p-1 hover:bg-white hover:text-primary"
            @click="onEdit(schedule)"
          >
            <svg viewBox="0 0 512 512" class="h-3 w-3">
              <path
                fill="currentColor"
                d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
                class=""
              ></path>
            </svg>
          </button>
          <button
            v-if="id !== 5 && !schedule.isRestriction"
            class="absolute top-6 right-0 p-1 hover:bg-white hover:text-primary"
            @click="onClone(schedule)"
          >
            <svg viewBox="0 0 24 24" class="h-3 w-3" fill="none">
              <path
                d="M14 16.75H6C5.27065 16.75 4.57118 16.4603 4.05546 15.9445C3.53973 15.4288 3.25 14.7293 3.25 14V6C3.25 5.27065 3.53973 4.57118 4.05546 4.05546C4.57118 3.53973 5.27065 3.25 6 3.25H14C14.7293 3.25 15.4288 3.53973 15.9445 4.05546C16.4603 4.57118 16.75 5.27065 16.75 6V14C16.75 14.7293 16.4603 15.4288 15.9445 15.9445C15.4288 16.4603 14.7293 16.75 14 16.75ZM6 4.75C5.66848 4.75 5.35054 4.8817 5.11612 5.11612C4.8817 5.35054 4.75 5.66848 4.75 6V14C4.75 14.3315 4.8817 14.6495 5.11612 14.8839C5.35054 15.1183 5.66848 15.25 6 15.25H14C14.3315 15.25 14.6495 15.1183 14.8839 14.8839C15.1183 14.6495 15.25 14.3315 15.25 14V6C15.25 5.66848 15.1183 5.35054 14.8839 5.11612C14.6495 4.8817 14.3315 4.75 14 4.75H6Z"
                fill="currentColor"
              />
              <path
                d="M18 20.75H10C9.27065 20.75 8.57118 20.4603 8.05546 19.9445C7.53973 19.4288 7.25 18.7293 7.25 18V16H8.75V18C8.75 18.3315 8.8817 18.6495 9.11612 18.8839C9.35054 19.1183 9.66848 19.25 10 19.25H18C18.3315 19.25 18.6495 19.1183 18.8839 18.8839C19.1183 18.6495 19.25 18.3315 19.25 18V10C19.25 9.66848 19.1183 9.35054 18.8839 9.11612C18.6495 8.8817 18.3315 8.75 18 8.75H16V7.25H18C18.7293 7.25 19.4288 7.53973 19.9445 8.05546C20.4603 8.57118 20.75 9.27065 20.75 10V18C20.75 18.7293 20.4603 19.4288 19.9445 19.9445C19.4288 20.4603 18.7293 20.75 18 20.75Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>
    </template>
  </draggable>

  <div
    v-for="(restriction, i) in restrictions"
    :key="i"
    class="
      border
      p-1
      mb-4
      text-sm
      rounded
      p-3
      relative
      cursor-pointer
      bg-red-100
      border-red
    "
  >
    <p class="text-red-500 font-medium w-100">Restrición</p>
    <label>{{ restriction.name }}</label>
  </div>

  <ScheduleForm
    v-if="allowCreate"
    :date="props.date"
    :week-key="props.weekKey"
    :is-current-week="props.isCurrentWeek"
    @onComplete="onCompleteCreateSchedule"
  />

  <!--
  <div
    v-for="restriction in restrictions"
    :key="restriction.key"
    class="border p-1 mt-4 text-sm rounded p-3 relative cursor-pointer"
    :class="'bg-red-100 border-red'"
  >
    <div style="min-height: 60px">
      <label class="text-red-500 font-medium">Restrición</label>
      <p>{{ restriction.name }}</p>
    </div>
  </div>
  -->
</template>

<style>
.element-card {
  position: relative;
  background-color: white;
  height: auto;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  min-height: 30px;
  margin-bottom: 10px;
  word-break: break-all;
  text-align: left;
}
</style>
