<template>
  <div class="grid grid-cols-3">
    <div class="col-span-2">
      <canvas
        ref="canvas"
        width="700"
        height="520"
        @mousemove="showCoordinates"
      ></canvas>
    </div>
    <div>
      <div class="mb-10">
        <button
          :disabled="loadingSave"
          class="bg-primary py-2 px-6 text-white flex"
          @click="onCaptureImage"
        >
          <LoadingButtonIcon v-if="loadingSave" />
          Tomar captura
        </button>
      </div>

      <Alert
        v-if="responseMessage"
        :type="responseType"
        :text="responseMessage"
      ></Alert>

      <div v-for="(polygon, i) in polygons" :key="i">
        <div class="grid grid-cols-5 pb-4">
          <div class="m-auto">
            <div
              class="rounded-full"
              :style="{
                'background-color': polygon.color,
                opacity: 0.5,
                width: '24px',
                height: '24px',
              }"
            ></div>
          </div>
          <div class="col-span-3">
            <div class="">
              <input
                v-model="polygon.name"
                type="text"
                class="
                  relative
                  p-2
                  w-full
                  bg-white
                  rounded
                  border
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                  focus:outline-none focus:shadow-outline
                "
                placeholder="Ingrese nombre del plano"
              />
            </div>
          </div>
          <div class="my-auto">
            <button @click="onDeletePolygon(i)" class="ml-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56976C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56976V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z"
                  fill="#E00F57"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  imageUrl: {
    type: String,
    required: true,
  },
  specialityKey: {
    type: String,
    required: true,
  },
  date: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["onComplete"]);

const store = useStore();

const loadingSave = ref(false);
const responseMessage = ref(null);
const responseType = ref(null);
const canvas = ref(null);
const context = ref(null);
const coordX = ref(0);
const coordY = ref(0);
const polygons = ref([]);
const polygonColor = ref("");
const counterPolygons = ref(1);
const clickPoints = ref([]);

// var biggest = 520;
// var axis;
// const resize = (x, y) => {
//   // so that the biggest axis is always {biggest} px
//   var ratio = x > y ? x / biggest : y / biggest;
//   axis = [x / ratio, y / ratio];
//   canvas.value.height = axis[0];
//   canvas.value.width = axis[1];
// };

const generateColor = () => {
  const alphabet = "0123456789ABCDEF";
  let color = "#";

  for (let i = 0; i < 6; i++) {
    color += alphabet[Math.floor(Math.random() * 16)];
  }

  return color;
};

const loadBackgroundImage = async (src) => {
  let rawImg = new Image();
  rawImg.src = src;
  rawImg.crossOrigin = "Anonymous";
  rawImg.onload = () => {
    // canvas.value.style.backgroundImage = "url(" + src + ")";
    context.value.drawImage(
      rawImg,
      0,
      0,
      canvas.value.width,
      canvas.value.height
    );

    // resize(rawImg.height, rawImg.width);
  };
};

const drawDot = (x, y) => {
  context.value.beginPath();
  context.value.arc(x, y, 4, 0, 2 * Math.PI);
  context.value.fillStyle = polygonColor.value;
  context.value.fill();
};

const drawPoly = (points, color) => {
  context.value.lineWidth = 2;
  var split = points.splice(0, points.length - 1);
  context.value.beginPath();
  context.value.moveTo(split[0][0], split[0][1]);
  for (let i of split.reverse()) context.value.lineTo(i[0], i[1]);
  context.value.globalAlpha = 0.5;
  context.value.fillStyle = color;
  context.value.fill();
  context.value.stroke();
};

const showCoordinates = (evt) => {
  coordX.value = evt.offsetX;
  coordY.value = evt.offsetY;
};

const margin = 10;

const isClosedtoOrigin = (x, y) => {
  const origin = clickPoints.value[0];
  const rangeX = [origin[0] - margin, origin[0] + margin];
  const rangeY = [origin[1] - margin, origin[1] + margin];
  return x > rangeX[0] && x < rangeX[1] && y > rangeY[0] && y < rangeY[1];
};

const resetCurrentPolygon = () => {
  polygonColor.value = generateColor();
  context.value.fillStyle = polygonColor.value;
  clickPoints.value = [];
};

const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

onMounted(() => {
  context.value = canvas.value.getContext("2d");

  loadBackgroundImage(props.imageUrl);

  resetCurrentPolygon();

  canvas.value.addEventListener("click", (evt) => {
    clickPoints.value.push([evt.offsetX, evt.offsetY]);
    drawDot(evt.offsetX, evt.offsetY);
    if (
      clickPoints.value.length > 1 &&
      isClosedtoOrigin(evt.offsetX, evt.offsetY)
    ) {
      drawPoly(clickPoints.value, polygonColor.value);
      polygons.value.push({
        name: `Polígono ${counterPolygons.value++}`,
        color: polygonColor.value,
        points: clickPoints.value,
      });
      resetCurrentPolygon();
    }
  });
});

const onDeletePolygon = (ind) => {
  context.value.clearRect(0, 0, canvas.value.width, canvas.value.height);
  polygons.value.splice(ind, 1);
  clickPoints.value = [];

  for (let i = 0; i < polygons.value.length; i++)
    drawPoly(polygons.value[i].points, polygons.value[i].color);
};

const onCaptureImage = async () => {
  try {
    loadingSave.value = true;
    responseMessage.value = null;
    responseType.value = null;

    if (!polygons.value.length)
      throw new Error("Es necesario que se agreguen áreas dibujadas");

    const img = canvas.value.toDataURL("image/png");
    await store.dispatch("createPlanDiary", {
      planDiary: {
        specialityKey: props.specialityKey,
        legend: polygons.value.map((p) => ({ name: p.name, color: p.color })),
        date: props.date,
        file: dataURLtoBlob(img),
      },
    });

    emit("onComplete");
  } catch (error) {
    responseMessage.value =
      error.message || "Ocurrió un error al adicionar el plano";
    responseType.value = "error";

    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};
</script>

<style scoped>
canvas {
  position: relative;
  width: 700px;
  height: 520px;
  display: inline-block;
  border: 2px solid #333;
  background-size: cover;
  cursor: crosshair;
}
</style>
