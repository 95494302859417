<script setup>
import { defineProps, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import draggable from "vuedraggable";
import Avatar from "@/components/inc/Avatar";
import IconDispatch from "@/components/icons/Dispatch";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  date: {
    type: String,
  },
  initialDate: {
    type: String,
    required: true,
  },
  ind: {
    type: Number,
    required: true,
  },
  departureKey: {
    type: String,
    required: true,
  },
  weekKey: {
    type: String,
    required: true,
  },
  isCurrentWeek: {
    type: Boolean,
    required: true,
  },
});

const store = useStore();

const schedules = ref([]);
const scheduleToEdit = ref(null);
const loadingEdit = ref(false);

const getDraftSchedule = computed(() => store.getters.getDraftSchedule);
const getDeparturesMap = computed(() => store.getters.getDeparturesMap);

watch(
  () => getDraftSchedule.value,
  (currentValue) => {
    if (!currentValue) return;

    let currentSchedule = currentValue.schedule;
    let schedulesFound = currentSchedule.filter((sch) =>
      schedules.value.find((s) => s.key === sch.key)
    );
    schedulesFound.forEach((schedule) => {
      let scheduleInd = schedules.value.findIndex(
        (sch) => sch.key === schedule.key
      );
      if (scheduleInd >= 0) {
        schedules.value[scheduleInd] = { ...schedule };
      }
    });

    let schedulesDeleted = schedules.value.filter(
      (sch) => !currentSchedule.find((s) => s.key === sch.key)
    );
    schedulesDeleted.forEach((schedule) => {
      let scheduleInd = schedules.value.findIndex(
        (sch) => sch.key === schedule.key
      );
      if (scheduleInd >= 0) {
        schedules.value.splice(scheduleInd, 1);
      }
    });
  },
  { deep: true }
);

const init = async () => {
  let allSchedules = [
    ...getDraftSchedule.value.schedule
      .filter((schedule) => schedule.departure.key === props.departureKey)
      .map((ds) => ({ ...ds })),
  ];

  schedules.value = allSchedules.sort((a, b) => {
    if (a.sort === undefined && b.sort === undefined) {
      return 0;
    } else if (a.sort === undefined) {
      return 1;
    } else if (b.sort === undefined) {
      return -1;
    } else {
      return a.sort - b.sort;
    }
  });
};

init();

const onMoveSchedule = async (event) => {
  try {
    loadingEdit.value = true;

    if (event.removed) {
      const departureSchedules = getDraftSchedule.value.schedule
        .filter(
          (schedule) =>
            schedule.departure.key === event.removed.element.departure.key &&
            schedule.key !== event.removed.element.key
        )
        .sort((a, b) => {
          const aPriority = a.sort || 0;
          const bPriority = b.sort || 0;

          return aPriority - bPriority;
        });

      const promises = [];

      for (let i = event.removed.oldIndex; i < departureSchedules.length; i++) {
        promises.push(
          store.dispatch("updateDraftSchedule", {
            schedule: {
              key: departureSchedules[i].key,
              departureKey: props.departureKey,
              sort: i + 1,
            },
          })
        );
      }

      await Promise.all(promises);
    }

    if (event.moved) {
      const departureSchedules = getDraftSchedule.value.schedule
        .filter(
          (schedule) =>
            schedule.departure.key === event.moved.element.departure.key
        )
        .sort((a, b) => {
          const aPriority = a.sort || 0;
          const bPriority = b.sort || 0;

          return aPriority - bPriority;
        });

      const promises = [];

      if (event.moved.oldIndex < event.moved.newIndex) {
        for (let i = event.moved.oldIndex + 1; i <= event.moved.newIndex; i++) {
          promises.push(
            store.dispatch("updateDraftSchedule", {
              schedule: {
                key: departureSchedules[i].key,
                departureKey: props.departureKey,
                sort: i,
              },
            })
          );
        }

        promises.push(
          store.dispatch("updateDraftSchedule", {
            schedule: {
              key: departureSchedules[event.moved.oldIndex].key,
              departureKey: props.departureKey,
              sort: event.moved.newIndex + 1,
            },
          })
        );
      }

      if (event.moved.oldIndex > event.moved.newIndex) {
        for (let i = event.moved.newIndex; i < event.moved.oldIndex; i++) {
          promises.push(
            store.dispatch("updateDraftSchedule", {
              schedule: {
                key: departureSchedules[i].key,
                departureKey: props.departureKey,
                sort: i + 2,
              },
            })
          );
        }

        promises.push(
          store.dispatch("updateDraftSchedule", {
            schedule: {
              key: departureSchedules[event.moved.oldIndex].key,
              departureKey: props.departureKey,
              sort: event.moved.newIndex + 1,
            },
          })
        );
      }

      await Promise.all(promises);
    }

    if (event.added) {
      const departureSchedules = getDraftSchedule.value.schedule
        .filter((schedule) => schedule.departure.key === props.departureKey)
        .sort((a, b) => {
          const aPriority = a.sort || 0;
          const bPriority = b.sort || 0;

          return aPriority - bPriority;
        });

      let sort = event.added.newIndex + 1;

      await store.dispatch("updateDraftSchedule", {
        schedule: {
          key: event.added.element.key,
          departureKey: props.departureKey,
          sort: sort,
        },
      });

      let isToSync = event.added.newIndex !== departureSchedules.length;
      if (isToSync) {
        const promises = [];

        for (let i = event.added.newIndex; i < departureSchedules.length; i++) {
          promises.push(
            store.dispatch("updateDraftSchedule", {
              schedule: {
                key: departureSchedules[i].key,
                departureKey: props.departureKey,
                sort: i + 2,
              },
            })
          );
        }

        await Promise.all(promises);
      }
    }

    scheduleToEdit.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingEdit.value = false;
  }
};
</script>

<template>
  <div class="flex mb-4">
    <div class="flex justify-center w-full">
      <p class="cursor-pointer">
        {{ getDeparturesMap[props.departureKey].name }}
      </p>
    </div>
  </div>

  <draggable
    :list="schedules || []"
    class="list-group"
    :id="props.date"
    group="schedules"
    tag="ul"
    handle=".handle"
    ghost-class="ghost"
    item-key="key"
    @change="onMoveSchedule"
  >
    <template #item="{ element: schedule }">
      <div :class="schedule.isRestriction ? '' : 'handle'">
        <div
          :id="schedule.id"
          class="border p-1 mb-4 text-sm rounded p-3 relative cursor-pointer"
          :class="
            schedule.status === 'Registered'
              ? 'bg-white'
              : schedule.status === 'Completed'
              ? 'bg-purple text-white'
              : 'hover:border-primary hover:bg-sky'
          "
        >
          <div style="min-height: 60px">
            <div v-if="schedule.sector">{{ schedule.sector.name }}</div>
            <label>{{ schedule.name }}</label>
            <div v-if="schedule.metered">
              {{ schedule.metered }}{{ schedule.unit }}
            </div>
            <div
              v-if="schedule.user || schedule.users"
              class="flex flex-wrap gap-2 mt-2"
            >
              <Avatar v-if="schedule.user" :member="schedule.user" />
              <span v-for="user in schedule.users || []" :key="user.key">
                <Avatar :member="user" />
              </span>
            </div>
            <div v-if="schedule.isToDispatch" class="text-right">
              <button class="mx-auto p-1 absolute bottom-0 right-0">
                <IconDispatch
                  :color="
                    schedule.status === 'Completed' ? '#FFFFFF' : undefined
                  "
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </draggable>
</template>

<style>
.element-card {
  position: relative;
  background-color: white;
  height: auto;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  min-height: 30px;
  margin-bottom: 10px;
  word-break: break-all;
  text-align: left;
}
</style>
