<template>
  <div>
    <h4 class="mb-6 text-secondary">Causa de no cumplimiento</h4>
  </div>
  <div v-if="scheduleList.length">
    <div
      v-for="schedule in scheduleList"
      :key="schedule.scheduleKey"
      class="pb-5"
    >
      <div class="flex pb-2 justify-between">
        <h3 class="text-sm">
          {{ schedule.departure }} -
          {{ schedule.sectorName }}
          <!-- {{ schedule.scheduleName ? " > " + schedule.scheduleName : "" }} -->
        </h3>
        <div class="w-8 ml-4">
          <button
            class="
              text-primary
              border
              text-sm
              font-medium
              border-primary
              px-1
              w-8
              h-8
            "
            @click="schedule.isShow = !schedule.isShow"
          >
            <!-- {{ schedule.isShow ? " [] " : " > " }} -->
            <div v-if="schedule.isShow">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mx-auto"
              >
                <path
                  d="M1.12592 10.9995L2.31015 10.9995C2.39068 10.9995 2.46647 10.9653 2.51384 10.9093L6.9997 5.55537L11.4856 10.9093C11.5329 10.9653 11.6087 10.9995 11.6893 10.9995L12.8735 10.9995C12.9761 10.9995 13.0361 10.8983 12.9761 10.8259L7.40866 4.17998C7.20655 3.93936 6.79286 3.93936 6.59233 4.17998L1.02487 10.8259C0.963285 10.8983 1.02329 10.9995 1.12592 10.9995Z"
                  fill="#0057FF"
                />
              </svg>
            </div>
            <div v-else>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mx-auto"
              >
                <path
                  d="M12.8741 2.99951H11.6899C11.6093 2.99951 11.5335 3.03369 11.4862 3.08975L7.0003 8.44365L2.51444 3.08975C2.46707 3.03369 2.39128 2.99951 2.31075 2.99951H1.12652C1.02388 2.99951 0.963884 3.10068 1.02388 3.17314L6.59134 9.81904C6.79345 10.0597 7.20714 10.0597 7.40767 9.81904L12.9751 3.17314C13.0367 3.10068 12.9767 2.99951 12.8741 2.99951Z"
                  fill="#0057FF"
                />
              </svg>
            </div>
          </button>
        </div>
      </div>
      <div v-if="schedule.isShow">
        <div class="mb-4">
          <label>Tipo</label>
          <div class="border p-1 w-full">
            <select
              v-model="schedule.typeKey"
              class="focus:outline-none w-full"
            >
              <option
                v-for="type in getTypesNonCompliance"
                :key="type.key"
                :value="type.key"
              >
                {{ type.name }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-if="schedule.typeKey === 'bc1691df-a5c4-433a-ab23-645bab281814'"
          class="mb-4"
        >
          <label>Subcontratista</label>
          <div class="border p-1 w-full">
            <select
              v-model="schedule.subcontractorKey"
              class="focus:outline-none w-full"
            >
              <option
                v-for="subcontactor in getSubcontractors"
                :key="subcontactor.key"
                :value="subcontactor.key"
              >
                {{ subcontactor.name }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <label>Causa</label>
          <div class="border p-1 w-full">
            <textarea
              v-model="schedule.cause"
              class="focus:outline-none"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div>
      <button
        class="rounded bg-primary py-2 px-8 text-white font-medium flex"
        :disabled="loadingSave"
        @click="onSaveCauses"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Guardar
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref, computed, watch } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["onComplete"]);

const store = useStore();

const scheduleList = ref([]);
const loadingSave = ref(false);
const responseMessage = ref(null);
const responseType = ref(null);

const getSchedule = computed(() => store.getters.getSchedule);
const getTypesNonCompliance = computed(
  () => store.getters.getTypesNonCompliance
);
const getSubcontractors = computed(() => store.getters.getSubcontractors);

watch(
  () => getSchedule.value,
  (schedule) => loadScheduleCauses(schedule?.schedule),
  { deep: true }
);

const loadScheduleCauses = (scheduleSelected) => {
  if (scheduleSelected) {
    let schedules = scheduleSelected.filter(
      (s) => s.status !== "Completed" && !s.isToDispatch
    );

    for (let schedule of schedules) {
      scheduleList.value.push({
        key: schedule.cause?.key || "",
        scheduleKey: schedule.key,
        scheduleName: schedule.name,
        sectorName: schedule.sector?.name,
        typeKey: schedule.cause?.types?.key || "",
        subcontractorKey: schedule.cause?.subcontractor?.key || "",
        cause: schedule.cause?.cause || "",
        departure: schedule.departure?.name || "",
        isShow: false,
      });
    }
  }
};

const init = () => {
  loadScheduleCauses(getSchedule.value.schedule);
};

init();

const onSaveCauses = async () => {
  try {
    loadingSave.value = true;
    responseType.value = null;
    responseMessage.value = null;

    let scheduleCauses = [];
    let schedule;
    let scheduleSelected = getSchedule.value.schedule;

    for (let i = 0; i < scheduleList.value.length; i++) {
      schedule = scheduleList.value[i];
      if (
        schedule.typeKey !== scheduleSelected[i].cause?.types?.key ||
        schedule.cause !== scheduleSelected[i].cause?.cause ||
        schedule.subcontractorKey !==
          scheduleSelected[i].cause?.subcontactor?.key
      )
        scheduleCauses.push(schedule);
    }

    let promises = [];

    for (let cause of scheduleCauses) {
      promises.push(
        store.dispatch(
          cause.key ? "updateScheduleCause" : "saveScheduleCause",
          { cause }
        )
      );
    }

    await Promise.all(promises);

    emit("onComplete");
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un error al guardar el registro de causas";

    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};
</script>
