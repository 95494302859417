<template>
  <div class="flex">
    <h4 class="my-auto w-60">Semana {{ props.initialDate }}</h4>

    <button
      class="rounded bg-primary py-2 px-8 text-white font-medium flex ml-2"
      type="submit"
      :disabled="loadingSaveDraft"
      @click="onSaveDraft"
    >
      <LoadingButtonIcon v-if="loadingSaveDraft" />
      Guardar semana
    </button>

    <button
      class="
        text-primary
        border
        text-sm
        font-medium
        border-primary
        p-2
        px-2
        ml-2
      "
      @click="emit('onActiveModeDraft')"
    >
      Cancelar
    </button>
  </div>

  <div v-if="loadingWeekDepartures" class="flex mt-2 min-h-screen pt-6">
    <draggable
      :list="weekDepartures"
      class="flex"
      group="columns"
      item-key="departureKey"
      @change="onMoveColumn"
    >
      <template #item="{ element: weekDeparture, index }">
        <div class="border-r flex-1" :key="weekDeparture.departureKey">
          <div class="border-r flex-1">
            <div :id="weekDeparture.departureKey" class="px-2">
              <DayList
                :id="weekDeparture.key"
                :ind="index"
                :initial-date="weekDeparture.initialDate"
                :departure-key="weekDeparture.departureKey"
                :week-key="props.weekKey"
                :is-current-week="
                  weekDeparture.initialDate === getWeekInitialDate
                "
              />
            </div>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import draggable from "vuedraggable";
import DayList from "./draftboard/DayList";

const emit = defineEmits(["onActiveModeDraft"]);

const props = defineProps({
  day: {
    type: String,
    required: true,
  },
  weekKey: {
    type: String,
    required: true,
  },
  initialDate: {
    type: String,
    required: true,
  },
  scheduleSelected: {
    type: Array,
  },
});

const store = useStore();

const loadingWeekDepartures = ref(false);
const loadingMove = ref(false);
const loadingSaveDraft = ref(false);
const date = ref("");
const weekDepartures = ref([]);

const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);
const getDraftWeekDepartures = computed(
  () => store.getters.getDraftWeekDepartures
);
const getDraftSchedule = computed(() => store.getters.getDraftSchedule);

watch(
  () => getDraftWeekDepartures.value,
  (value) => {
    weekDepartures.value = value.sort((a, b) => {
      const aPriority = a.sort || 0;
      const bPriority = b.sort || 0;

      return aPriority - bPriority;
    });
  },
  { deep: true }
);

const init = async () => {
  date.value = dayjs(props.initialDate).subtract(7, "day").format("YYYY-MM-DD");

  loadingWeekDepartures.value = false;

  await store.dispatch("getAllWeekDepartures", {
    initialDate: date.value,
    isDraft: true,
  });

  await store.dispatch("findScheduleByWeek", {
    weekDate: date.value,
    isDraft: true,
  });

  loadingWeekDepartures.value = true;
};

init();

const onMoveColumn = async (event) => {
  try {
    loadingMove.value = true;

    if (event.removed) {
      const weekDepartures = getDraftWeekDepartures.value
        .filter(
          (schedule) =>
            schedule.departureKey === event.removed.element.departureKey &&
            schedule.key !== event.removed.element.key
        )
        .sort((a, b) => {
          const aPriority = a.sort || 0;
          const bPriority = b.sort || 0;

          return aPriority - bPriority;
        });

      const promises = [];

      for (let i = event.removed.oldIndex; i < weekDepartures.length; i++) {
        promises.push(
          store.dispatch("updateDraftWeekDeparture", {
            weekDeparture: {
              key: weekDepartures[i].key,
              sort: i,
            },
          })
        );
      }

      await Promise.all(promises);
    }

    if (event.moved) {
      const weekDepartures = getDraftWeekDepartures.value.sort((a, b) => {
        const aPriority = a.sort || 0;
        const bPriority = b.sort || 0;

        return aPriority - bPriority;
      });

      const promises = [];

      if (event.moved.oldIndex < event.moved.newIndex) {
        for (let i = event.moved.oldIndex + 1; i <= event.moved.newIndex; i++) {
          promises.push(
            store.dispatch("updateDraftWeekDeparture", {
              weekDeparture: {
                key: weekDepartures[i].key,
                sort: i - 1,
              },
            })
          );
        }

        promises.push(
          store.dispatch("updateDraftWeekDeparture", {
            weekDeparture: {
              key: weekDepartures[event.moved.oldIndex].key,
              sort: event.moved.newIndex,
            },
          })
        );
      }

      if (event.moved.oldIndex > event.moved.newIndex) {
        for (let i = event.moved.newIndex; i < event.moved.oldIndex; i++) {
          promises.push(
            store.dispatch("updateDraftWeekDeparture", {
              weekDeparture: {
                key: weekDepartures[i].key,
                sort: i + 1,
              },
            })
          );
        }

        promises.push(
          store.dispatch("updateDraftWeekDeparture", {
            weekDeparture: {
              key: weekDepartures[event.moved.oldIndex].key,
              sort: event.moved.newIndex,
            },
          })
        );
      }

      await Promise.all(promises);
    }
  } catch (error) {
    console.error(error);
  } finally {
    loadingMove.value = false;
  }
};

const onSaveDraft = async () => {
  try {
    loadingSaveDraft.value = true;

    let promises = [];

    weekDepartures.value.forEach((weekDeparture) => {
      promises.push(
        store.dispatch("createWeekDeparture", {
          weekDeparture: {
            initialDate: props.initialDate,
            sort: weekDeparture.sort,
            departureKey: weekDeparture.departureKey,
          },
        })
      );
    });

    getDraftSchedule.value.schedule.forEach((schedule) => {
      let selectedMembersKeys = [];

      if (schedule.user) selectedMembersKeys.push(schedule.user.key);

      if (schedule.users)
        selectedMembersKeys = selectedMembersKeys.concat(
          schedule.users.map((user) => user.key)
        );
      let isCurrentWeek = props.initialDate === getWeekInitialDate.value;

      promises.push(
        store.dispatch("createSchedule", {
          schedule: {
            type: "Weekly",
            name: schedule.name,
            weekKey: props.weekKey,
            date: props.initialDate,
            color: schedule.color,
            status: isCurrentWeek ? "RegisteredAfterDate" : "Registered",
            timeout: isCurrentWeek,
            isToDispatch: schedule.isToDispatch,
            sort: schedule.sort,
            userKey: selectedMembersKeys.length ? selectedMembersKeys[0] : null,
            usersKey: selectedMembersKeys.length
              ? selectedMembersKeys.splice(1)
              : [],
            sectorKey: schedule.sector ? schedule.sector.key : null,
            unit: schedule.unit,
            metered: schedule.metered,
            departureKey: schedule.departure ? schedule.departure.key : null,
          },
        })
      );
    });

    await Promise.all(promises);

    emit("onActiveModeDraft");
  } catch (error) {
    console.error(error);
  } finally {
    loadingSaveDraft.value = false;
  }
};
</script>

<style>
.ghost {
  opacity: 0.5;
}
</style>
