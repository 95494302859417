<template>
  <Modal :open="open" :keep-open="true" width="w-1/3" @update:open="onClose">
    <template #body>
      <span class="font-medium"> Columna: {{ weekDeparture.name }} </span>
      <br />
      <div class="pt-5">
        <Form
          @submit="onSave"
          :validation-schema="{
            name: 'required',
          }"
        >
          <div class="relative mb-5">
            <Field v-model="name" name="name" v-slot="{ field }">
              <input
                v-bind="field"
                type="text"
                class="
                  py-2
                  px-2
                  w-full
                  bg-white
                  rounded-sm
                  border
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                  focus:shadow-outline
                "
                placeholder="Nueva columna"
                autocomplete="off"
                @input="filterSuggestions"
              />
            </Field>
            <div
              v-show="filteredSuggestions.length > 0 && showSuggestions"
              class="absolute w-full bg-white border rounded-sm mt-1"
              :style="{
                'max-height': '100px',
                'z-index': 1,
                'overflow-y': 'auto',
              }"
            >
              <ul>
                <li
                  v-for="suggestion in filteredSuggestions"
                  :key="suggestion"
                  @click="selectSuggestion(suggestion)"
                  class="py-2 px-2 hover:bg-gray-100 cursor-pointer"
                >
                  {{ suggestion.name }}
                </li>
              </ul>
            </div>
            <div class="text-left">
              <ErrorMessage name="name" v-slot="{ message }">
                <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
              </ErrorMessage>
            </div>
          </div>

          <div class="pt-3 pb-5 sm:flex sm:flex-row-reverse">
            <button
              class="
                rounded
                bg-primary
                py-2
                px-8
                text-white
                font-medium
                flex
                ml-2
              "
              type="submit"
              :disabled="loading"
            >
              <LoadingButtonIcon v-if="loading" />
              Actualizar
            </button>
            <button
              type="text"
              class="
                mt-3
                w-full
                inline-flex
                justify-center
                rounded-md
                border border-gray-300
                shadow-sm
                px-4
                py-2
                bg-white
                text-base
                font-medium
                text-gray-700
                hover:bg-gray-50
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
                sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
              "
              @click="onClose()"
              ref="cancelButtonRef"
            >
              Cancelar
            </button>
          </div>
        </Form>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";

const emit = defineEmits(["update:open"]);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  weekDeparture: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const { value: name } = useField("name");

const filteredSuggestions = ref([]);
const showSuggestions = ref(false);
const loading = ref(false);

const getDepartures = computed(() => store.getters.getDepartures);
const getWeekDepartures = computed(() => store.getters.getWeekDepartures);

const filterSuggestions = () => {
  if (!name.value) {
    filteredSuggestions.value = [];
    showSuggestions.value = false;
  } else {
    filteredSuggestions.value = getDepartures.value.filter(
      (d) =>
        d.name.toLowerCase().includes(name.value.toLowerCase()) &&
        !getWeekDepartures.value.find((wd) => wd.departureKey === d.key)
    );
    showSuggestions.value = true;
  }
};

const selectSuggestion = (suggestion) => {
  name.value = suggestion.name;
  showSuggestions.value = false;
};

const onClose = () => {
  emit("update:open", false);
};

const onSave = async () => {
  try {
    loading.value = true;

    let departureKey;

    const departure = getDepartures.value.find((d) =>
      d.name.toLowerCase().includes(name.value.toLowerCase())
    );

    if (departure) {
      departureKey = departure.key;
    } else {
      const response = await store.dispatch("createDeparture", {
        departure: { name: name.value },
      });
      departureKey = response.key;
    }

    await store.dispatch("updateWeekDeparture", {
      weekDeparture: {
        key: props.weekDeparture.key,
        departureKey,
      },
    });

    onClose();
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};
</script>
